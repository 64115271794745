import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/shared/products.service';
import { ActivatedRoute, Router } from '@angular/router'; 
import { Category } from '../shared/category.model';
import { ProductList } from '../shared/product-list.model';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserAddress } from '../shared/user-address.model';

@Component({
  selector: 'app-productlist',
  templateUrl: './productlist.component.html',
  styleUrls: ['./productlist.component.css']
})
export class ProductlistComponent implements OnInit {
  //listCategory : Category;
  listAddress : UserAddress[]; 
  listProduct : ProductList[];
  angForm: FormGroup; 
  selectedAddress: number = 0;

  constructor(private router: Router,private route: ActivatedRoute, public service : ProductsService,private fb: FormBuilder) { 
    this.createForm();
  }

  ngOnInit(): void {
    if(localStorage.getItem("userId") != null){
      this.GetCountry();
      this.GetAddress();
      this.GetProductList();  
      this.GetCartCount();
    }else{
      this.router.navigate(['login']);
    }
    
  }
  GetCartCount(){    
    this.service.GetCartCount(localStorage.getItem("userId")).subscribe((res:any) =>{
      (<HTMLInputElement>document.getElementById('lblCart')).innerHTML = String(res);
      (<HTMLInputElement>document.getElementById('lblCartM')).innerHTML = String(res);
    })
  }

  
  GetAddress(){
    this.service.GetAddress(localStorage.getItem("userId")).subscribe((data: any)=>{
      if(data.length > 0){
      this.selectedAddress = data[0].aId;
       this.listAddress = data as  UserAddress[];
       if(data.length == 3){
        (<HTMLButtonElement>document.getElementById('btnAddAddress')).style.display = 'none';
       }else{
        (<HTMLButtonElement>document.getElementById('btnAddAddress')).removeAttribute("style");
       }
       this.AddToCartAddress();
      }else{
        this.selectedAddress =0;
        this.showAddress();
      }
    });  
  }

  onSelectionChange(selectedValue : number ){
    this.selectedAddress = selectedValue;
    this.AddToCartAddress();
 }
 
DeleteAddress(){
 this.service.DeleteAddress(this.selectedAddress).subscribe(
   res => { 
    this.GetAddress();
 });  
}

 GetCountry(){
   let cId : number = 0;
   this.service.GetCountryCity(0).subscribe((data: any)=>{
     for(let i =0; i  < data.length;i++){
       if(i == 0){
         this.GetState(data[i].id);
       }
       (<HTMLInputElement>document.getElementById('Country')).innerHTML += '<option value='+ data[i].id +'>'+ data[i].name +'</option>';
     } 
   });  
 }

 GetState(id : number){ 
   this.service.GetCountryCity(id).subscribe((stateData: any)=>{
     for(let i =0; i  < stateData.length;i++){
       if(i == 0){ 
        this.GetCity(stateData[i].id);
       }
       (<HTMLInputElement>document.getElementById('State')).innerHTML += '<option value='+ stateData[i].id +'>'+ stateData[i].name +'</option>';
     } 
   });  
 }

 GetCity(id : number){
   this.service.GetCountryCity(id).subscribe((data: any)=>{
     for(let i =0; i  < data.length;i++){
       (<HTMLInputElement>document.getElementById('city')).innerHTML += '<option value='+ data[i].id +'>'+ data[i].name +'</option>';
     } 
   });  
 }

 onSubmit() {
  if (this.angForm.invalid) { 
      return;
  }
  this.angForm.value.Country = (<HTMLInputElement>document.getElementById('Country')).value;
  this.angForm.value.State = (<HTMLInputElement>document.getElementById('State')).value;
  this.angForm.value.city = (<HTMLInputElement>document.getElementById('city')).value;
  this.angForm.value.userId = localStorage.getItem("userId");
   this.service.AddAddress(this.angForm.value).subscribe(
    res => { 
      this.selectedAddress = Number(res);
      (<HTMLDivElement>document.getElementById('divAddress')).style.display = 'block';
      (<HTMLDivElement>document.getElementById('divAddForm')).style.display = 'none';
      this.GetAddress();
  });
}

showAddress(){
  (<HTMLDivElement>document.getElementById('divAddress')).style.display = 'none';
  (<HTMLDivElement>document.getElementById('divAddForm')).style.display = 'block';
}
AddToCartAddress(){
  this.service.AddCartAddress(Number(localStorage.getItem("userId"))  , Number(this.selectedAddress) ).subscribe(
    res => { 
    });
  }

  
  createForm() {
    this.angForm = this.fb.group({
       fname: ['', Validators.required ],
       lname: ['', Validators.required ],
       address: ['', Validators.required ],
       address2: ['', Validators.required ],
       pincode: ['', Validators.required],
       mobileNo: ['', Validators.required],
       email: ['', Validators.required ],
       Country:[''],
       State: [''],
       city: [''],
       userId: ['']
    });
  }




  GetProductList(){
    var strHtml = '';
    var strHtmlCol = '';
    var strHtmlRow = '';
    var strHtmlRdo = '';

    this.service.GetCateogyForProduct("0").subscribe((data: any)=>{
      var listCategory = data;
      //console.log(listCategory);
      this.service.GetProductList(localStorage.getItem("userId")).subscribe((dataProd: any)=>{
        var prod = dataProd;
        this.listProduct = dataProd  as ProductList[];
        for(let i =0; i  < listCategory.length;i++){
          strHtml += '<div class="template mt-5">';
          strHtml += '<h5 class="mt-2 mb-4">'+  listCategory[i].category +'</h5>';
          strHtml += '<div class="scrolling outer">';
          strHtml += '<div class="inner">';
          strHtml += '<table class="table table-bordered table-striped">';

          var lisProd =  prod.filter(function(prod) {
            return prod.categoryId == listCategory[i].categoryId && prod.catGroup == listCategory[i].catGroup;
          });
          //console.log(lisProd);
          
          var uniq = lisProd.map(item => item.displayUnit)
          .filter((value, index, self) => self.indexOf(value) === index);
          strHtmlCol = '<tr class="bg-secondary text-white"><th></th><td>Price</td>';
         
          for(let k =0; k  < uniq.length;k++){
            strHtmlCol += '<td>'+ lisProd[k].displayUnit +'</td>';
          }
          strHtmlCol += '</tr>';
          var cntRow = 0;
          for(let j =0; j  < lisProd.length;j++){
            if(j == cntRow){
              strHtmlRow += '<tr><th><div>'+ lisProd[j].pTitle +'</div></th>';
              //if(window.innerWidth == 375){
              //strHtmlRow += '<td style="height:1000px;"><div ><i class="fa fa-rupee"></i>&nbsp;'+ lisProd[j].price  +'</div></td>';
              //}else{

              //}

              if(lisProd[j].pTitle.length <= 25){
                strHtmlRow += '<td class="titleheight1"><div><i class="fa fa-rupee"></i>&nbsp;'+ lisProd[j].price  +'</div></td>';
              }else if(lisProd[j].pTitle.length >= 26 && lisProd[j].pTitle.length <= 36){
                strHtmlRow += '<td class="titleheight2"><div><i class="fa fa-rupee"></i>&nbsp;'+ lisProd[j].price  +'</div></td>';
              }else{
                strHtmlRow += '<td class="titleheight3"><div><i class="fa fa-rupee"></i>&nbsp;'+ lisProd[j].price  +'</div></td>';
              }
            }             
            strHtmlRow += '<td><div><input  type="radio" name="rdo_' + lisProd[j].productId + '" id="rdo_' + lisProd[j].productId + '_' + lisProd[j].unit  + '" ' + lisProd[j].cartUnit  +' ></div></td>';
            
           if(j == cntRow + uniq.length - 1){
            strHtmlRow += '</tr>';
            cntRow = j + 1;
           }
            
          }
         strHtml +=    strHtmlCol +  strHtmlRow ; 
          
          strHtml += '</table>';
          strHtml += '</div>';
          strHtml += '</div>';
          strHtml += '</div>';
          strHtmlCol = '';
          strHtmlRow = '';
        }
        //console.log(strHtml);
        (<HTMLDivElement>document.getElementById("tempHtml")).innerHTML =  strHtml;
        for(let prd =0; prd < this.listProduct.length;prd++){
          let rdo = document.getElementById('rdo_' + this.listProduct[prd].productId + '_' + this.listProduct[prd].unit  +  '');
          rdo.addEventListener("click", (event: Event) => {
            this.addToCart(event,Number(this.listProduct[prd].categoryId), Number(this.listProduct[prd].productId), Number(this.listProduct[prd].unit), String(this.listProduct[prd].displayUnit), Number(this.listProduct[prd].price));
          });
        }
      });  
    });   
  }
  

  addToCart( obj ,catId,productId, sUnt,dUnit, price){
    console.log(productId);
    this.service.AddToCartList(Number(localStorage.getItem("userId"))  , productId ,sUnt, price,dUnit,catId).subscribe(
      res => { 
       //this.viewCart();
       this.GetCartCount();
    }); 
  }

  viewCart(){
    if(this.selectedAddress > 0){
      this.router.navigate(['cart']);
    }else{
      alert('Please Fill Shipping Details !');
    }
  }
  

}
