import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderAddress } from '../shared/order-address.model';
import { OrderProduct } from '../shared/order-product.model';
import { ProductsService } from '../shared/products.service';

@Component({
  selector: 'app-orderinfo',
  templateUrl: './orderinfo.component.html',
  styleUrls: ['./orderinfo.component.css']
})
export class OrderinfoComponent implements OnInit {
  public id: string = "0";
  public strTotal: string = "0";
  public strDisc: string = "0";
  public strFTotal: string = "0";
  listOrderAddress : OrderAddress[];
  listOrderProduct : OrderProduct[];
  isShow: boolean = false;

  constructor(private router: Router,private route: ActivatedRoute, public service : ProductsService) { }

  ngOnInit(): void {
    if(localStorage.getItem("userId") != null){
      this.id = localStorage.getItem("userId");
      this.service.GetCartCount(localStorage.getItem("userId")).subscribe((res:any) =>{
        (<HTMLInputElement>document.getElementById('lblCart')).innerHTML = String(res);
        (<HTMLInputElement>document.getElementById('lblCartM')).innerHTML = String(res);
      })
    }
    this.refreshData();
  }

  refreshData(){
       
    this.service.GetCustomerAddress(this.route.snapshot.paramMap.get('id'),this.id).subscribe((response: any) => {
     // console.log(response);
      this.listOrderAddress = response as OrderAddress[];
       
      });
    this.service.GetCustomerProduct(this.route.snapshot.paramMap.get('id'),this.id).subscribe((response: any)=>{
      this.listOrderProduct = response as OrderProduct[];
      //this.listOrderProduct = response.body as OrderProduct
      this.fnTotalPrice();
       
        this.strDisc =String(this.listOrderProduct[0]["disc"]);
        this.strFTotal = String(Number(this.strTotal) - Number(this.strDisc));
        //(<HTMLTableRowElement>document.getElementById('trDisc')).style.display = "none";   
        //(<HTMLTableRowElement>document.getElementById('trFTotal')).style.display = "none";   
      
    });
  }

  fnTotalPrice()  {
    let total : number = 0;
    for(let i =0;i < this.listOrderProduct.length;i++){ 
      total +=  Number(this.listOrderProduct[i]["price"])  * Number(this.listOrderProduct[i]["qty"]);
    } 
    //(<HTMLLabelElement>document.getElementById('lblTotal')).innerHTML = String(total); 
    this.strTotal = String(total);
  }

}
