import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RazorService } from '../shared/razor.service';
 
declare var Razorpay: any; 
 

@Component({
  selector: 'app-razor-test',
  templateUrl: './razor-test.component.html',
  styleUrls: ['./razor-test.component.css']
})
export class RazorTestComponent implements OnInit {
  ordNo : string;
  razorPayOptions = {
    "amount": '', // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise or INR 500.
    "currency": "INR",
    "name": "Amrutam",
    "description": "Amrutam Payment",
    "order_id":"",
    "reference_id" : "",
    "image":"https://aranyamamrutam.com/assets/images/logo.jpg",
    "prefill": {
      "name": "",
      "email": "",
      "contact": ""
  },
    "theme": {
      "color": "#3399cc"
  },
    "handler": function (response) {
      
     
    },
    "modal": {
      "ondismiss": function(){
          console.log('Checkout form closed');
      }
    },
    "retry" : {"enabled" : false}
};

  constructor(public serviceRazor : RazorService, private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.ordNo = this.route.snapshot.paramMap.get('ordNo');
    this.serviceRazor.GetToken(5 , "test123").subscribe(
      res => {  
        localStorage.setItem("ordNo", this.ordNo);
        
        this.razorPayOptions.order_id = res["Attributes"].id;
        this.razorPayOptions.amount =  res["Attributes"].amount;
        this.razorPayOptions.description = "Amrutam Test";
        this.razorPayOptions.prefill.name ="test User";
        this.razorPayOptions.prefill.email ="abhijittawde@gmail.com";
        this.razorPayOptions.prefill.contact ="9773416828";
        this.razorPayOptions.retry.enabled= false;
        this.razorPayOptions.handler = this.razorPayResponseHandler;
        this.razorPayOptions.modal.ondismiss = this.razorPayCloseWindowHandler;
        this.razorPayOptions.reference_id = this.ordNo;

        var rzp1 = new Razorpay(this.razorPayOptions);
        rzp1.on('payment.failed', function (response){
          console.log("failure");
       console.log(response.error.metadata.order_id);
       // location.href='message/' +  response.error.metadata.payment_id + '/0/failure';
       
      });
      rzp1.open();
      //console.log("opened")
    }); 
  }
razorPayCloseWindowHandler(){
  location.href='message/0/0/failure';
}

  razorPayResponseHandler(response){
    console.log(response.razorpay_order_id);
    //location.href='message/' +  response.razorpay_payment_id + '/0/success';
  }

}

 
