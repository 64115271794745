import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { ProductsComponent } from './products/products.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { MessageComponent } from './message/message.component';
import { LoginComponent } from './login/login.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component'; 
import { MyorderComponent } from './myorder/myorder.component';
import { OrderinfoComponent } from './orderinfo/orderinfo.component';
import { PlaceorderComponent } from './placeorder/placeorder.component';
import { RazorService } from './shared/razor.service';
import { RazorTestComponent } from './razor-test/razor-test.component';
import { ProductlistComponent } from './productlist/productlist.component';
   

const routes: Routes = [{
  path: 'cart',
  component: CartComponent
},{
  path: 'cart/:id',
  component: CartComponent
},
{
  path: 'products/:id',
  component: ProductsComponent
}, 
{
  path: 'productlist',
  component: ProductlistComponent
}, 
{
  path: 'checkout',
  component: CheckoutComponent,
  pathMatch: 'full'
}, 
{
  path: 'checkout/:id',
  component: CheckoutComponent,
  pathMatch: 'full'
},
{
  path: 'message/:id/:status',
  component: MessageComponent,
  pathMatch: 'full'
}, 
{
  path: 'message/:id/:aId/:status',
  component: MessageComponent,
  pathMatch: 'full'
}, 
{
  path: 'login',
  component: LoginComponent,
  pathMatch: 'full'
}, 
{
  path: 'myorder',
  component: MyorderComponent,
  pathMatch: 'full'
}, 
{
  path: 'Orderinfo/:id',
  component: OrderinfoComponent, 
  pathMatch: 'full'
}, 
{
  path: 'aboutUs',
  component: AboutUsComponent,
  pathMatch: 'full'
},
{
  path: 'terms',
  component: TermsComponent,
  pathMatch: 'full'
},
{
  path: 'disclaimer',
  component: DisclaimerComponent,
  pathMatch: 'full'
},
{
  path: 'policy',
  component: PolicyComponent,
  pathMatch: 'full'
}, 
{
  path: 'placeorder/:ordNo',
  component: PlaceorderComponent,
  pathMatch: 'full'
},
{
  path: 'home/:msg',
  component: IndexComponent,
  pathMatch: 'full'
}, 
{
  path: 'home',
  component: IndexComponent,
  pathMatch: 'full'
}, 
{
  path: 'razor/:ordNo',
  component: RazorTestComponent,
  pathMatch: 'full'
}, 
{ path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [  ];
