<div class="yellow-bg margin-from-nav">
    <div class="container-fluid">
    <div class="row">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="home">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Terms and Conditions</li>
        </ol>
    </div>
  </div>
  </div>
<div class="container-fluid contact-page">
    <div class="row">
        <div class="col-md-12">
            <div class="card" id="success" >
                <div class="card-header bg-primary text-white"> Terms and Conditions
                </div>                 
                <div class="card-body"  id="divContent"  > 
                    
                </div>
                <div class="card-body">&nbsp;</div>
            </div>    
        </div>  
    </div>
</div>