import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/shared/products.service';
import { CartDetails } from '../shared/cart-details.model';
 

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  public Msg: string;  
  public TotalPrice : number = 0;
  listCart : CartDetails[];
  

  constructor(public service : ProductsService, private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    if(localStorage.getItem("userId") != null){ 
      this.service.GetDeliveryDate(Number(localStorage.getItem("userId"))).subscribe((deliveryDate: any)=>{
        (<HTMLLabelElement>document.getElementById('lblDeliveryDate')).innerHTML = deliveryDate; 
      }); 
      this.GetCartCount();
      this.refreshData();
    } 
    else
    {
      this.router.navigate(['home']);
    }
  }

  GetCartCount(){    
    this.service.GetCartCount(localStorage.getItem("userId")).subscribe((res:any) =>{
      (<HTMLInputElement>document.getElementById('lblCart')).innerHTML = String(res);
      (<HTMLInputElement>document.getElementById('lblCartM')).innerHTML = String(res);
    })
  }

  refreshData(){
    //this.GetCartCount();
    this.service.GetCart(String(localStorage.getItem("userId"))).subscribe((response: any)=>{
      this.listCart = response as CartDetails[];
      this.fnTotalPrice();
    });
  }

  CheckCouponDisc(total : number){
    this.service.CheckOffers(String(localStorage.getItem("userId"))).subscribe((response: any)=>{
      if(response == "0"){
        (<HTMLInputElement>document.getElementById('divFinalTotal')).style.display = "none";
      }
      (<HTMLInputElement>document.getElementById('lblFinalTotal')).innerHTML = String(total); 
    });

  }

 fnCalculateDisc() {
    this.fnGetCouponDisc(Number((<HTMLInputElement>document.getElementById('lblTotal')).innerHTML));
    
  }

  fnGetCouponDisc(total : number){
    this.service.GetCouponDisc(String(localStorage.getItem("userId")), (<HTMLInputElement>document.getElementById('txtCode')).value).subscribe((response: any)=>{
        if(response.msgCode == "1"){
          alert(response.msg);
        }
        else{
          (<HTMLInputElement>document.getElementById('lblDisc')).innerHTML = String(response.msg); 
          (<HTMLInputElement>document.getElementById('lblFinalTotal')).innerHTML = String(total - Number(response.msg)); 
        }      
    });
  }

  fnDeleteCart(productId : number, index : number){
    this.service.DeleteCart(productId,Number(String(localStorage.getItem("userId")))).subscribe(
      res => { 
      // this.refreshData();
      this.listCart.splice(index,1);
       this.fnTotalPrice();
       this.GetCartCount();
    });  
  }

  fnTotalPrice()  {
    let total : number = 0;
    for(let i =0;i < this.listCart.length;i++){ 
      total +=  Number(this.listCart[i]["Price"])  * Number(this.listCart[i]["qty"]);
    } 
    (<HTMLInputElement>document.getElementById('lblTotal')).innerHTML = String(total); 
    this.CheckCouponDisc(total);
    if(this.listCart.length == 0 ){
      (<HTMLInputElement>document.getElementById('divTotal')).style.display = "none";
      (<HTMLInputElement>document.getElementById('divEmpty')).style.display = "block";
      (<HTMLButtonElement>document.getElementById('btnCheckOut')).style.display = "none";
    }else{
      (<HTMLInputElement>document.getElementById('divTotal')).style.display = "block";
      (<HTMLInputElement>document.getElementById('divEmpty')).style.display = "none";
      (<HTMLButtonElement>document.getElementById('btnCheckOut')).style.display = "block";
    }
  }

  fnContinue(){
    this.router.navigate(['productlist']);
  }

  fnCheckOut(){
     //if(Number((<HTMLInputElement>document.getElementById('lblDisc')).innerHTML) > 0){
      this.service.UpdateCartForOffer((<HTMLInputElement>document.getElementById('lblDisc')).innerHTML,
      (<HTMLInputElement>document.getElementById('txtCode')).value, String(localStorage.getItem("userId"))).subscribe(
        res => { 
          this.router.navigate(['checkout']);
        });
    // }else{
    //  this.router.navigate(['checkout']);
    // } 
  }

  placeOrder(){ 
   // console.log((<HTMLInputElement>document.getElementById('txtCode')).value);
    if((<HTMLInputElement>document.getElementById('txtCode')).value == ''){
      this.service.AddOrder(0, Number(localStorage.getItem("userId")), '0').subscribe(
            res => {  
              if (res[0].billId > 0){
                location.href = 'placeorder/' + res[0].billNumber;
              }
            }); 
    }else{
      this.service.AddOrderWithOffer(0, Number(localStorage.getItem("userId")), '0',(<HTMLInputElement>document.getElementById('lblDisc')).innerHTML,
          (<HTMLInputElement>document.getElementById('txtCode')).value).subscribe(
          res => {  
            if (res[0].billId > 0){
              location.href = 'placeorder/' + res[0].billNumber;
           }
          });  
        }
  //  if((<HTMLInputElement>document.getElementById('txtCode')).value == ''){
  //    this.service.AddOrder(0, Number(localStorage.getItem("userId")), '0').subscribe(
  //    res => {  
  //      if (res[0].billId > 0){
  //        location.href = 'placeorder/' + res[0].billNumber;
  //      }
  //    });  
  //  }else{
  //    this.service.AddOrderWithOffer(0, Number(localStorage.getItem("userId")), '0',(<HTMLInputElement>document.getElementById('lblDisc')).innerHTML,
  //    (<HTMLInputElement>document.getElementById('txtCode')).value).subscribe(
  //    res => {  
  //      if (res[0].billId > 0){
  //        location.href = 'placeorder/' + res[0].billNumber;
   //     }
  //    });  
  //  }
     
  } 

}
