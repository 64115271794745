<div class="yellow-bg  margin-from-nav">
    <div class="container-fluid">
    <div class="row">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="home">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Product</li>
        </ol>
    </div>
  </div>
  </div>


<div class="container-fluid cart-page">
    <div class="row">
        <div class="col-md-8 offset-md-2">
            <div class="card p-4">
               <h5> Amrutam Organic (Weekly Market)</h5>
               <h6>Read Carefully</h6>
               <ol class="mb-3">
                <li> Rates may fluctuate every order as per market condition. </li>
                <li> All orders are only accepted on 77000 33338 ( Call or Whatsapp ). </li>
                <li> Order once placed will not be cancelled or replaced.</li>
                <li> Order will be confirmed only after full payment.</li>
                <li> Payment modes – Google Pay , PayTM , Net Banking & Card Payment.</li>
                <li> You will get your delivery on Friday after confirmation.</li>
                <li> Free Home delivery on order above Rs. 500 in Mumbai only.</li>
                <li> Kindly check the order during delivery.</li>
                <li> Remove Vegetables, Cereals & Fruits From Plastic Bags & Store In Air Tight Containers.</li>
                <li> Fruits & Vegetables are perishable items so store in Cool & Dry place.</li>
                <li> If any queries call or send messages on Whatsapp on 77000 33338.</li>
               </ol> 
                    <form>
                    <div class="row">
                        <div class="col-md-12">
                            
                                <div class="card-header bg-primary text-white"><i class="fa fa-envelope"></i> Shipping Details
                                </div>
                                <div class="card-body"  id="divAddress" >
                                    <div class="form-check"  *ngFor="let lstAdd of listAddress;  let in=index">
                                        <input class="form-check-input"   type="radio" name="exampleRadios" id="exampleRadios{{in}}" value="{{ lstAdd.aId }}"  [checked]="in === 0" (change)="onSelectionChange(lstAdd.aId)" >
                                        <label class="form-check-label" for="exampleRadios{{in}}">
                                            {{ lstAdd.fname + ' ' + lstAdd.lname  }} <br />
                                            {{ lstAdd.address + ', ' + lstAdd.address2 + ', ' + lstAdd.Country + ', ' + lstAdd.State  + ', ' + lstAdd.city + ' - ' + lstAdd.pincode }}
                                        </label>
                                      </div>     
                                      <div class="row mt-3">
                                        <div class="col-md-12">         
                                            <div class="mx-auto">
                                                <button type="submit" id="btnAddAddress" class="btn btn-success text-right" (click)="showAddress()">New Address</button>&nbsp;
                                                <button type="submit" class="btn btn-secondary text-right" (click)="DeleteAddress()">Delete Address</button>
                                            </div>
                                        </div>
                                    </div>              
                                                   
                                </div>
                
                
                                <form  [formGroup]="angForm" >
                                <!-- Add Address -->
                                <div class="card-body" id="divAddForm" style="display: none;">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="name">First Name</label>
                                                <input type="text" class="form-control" id="fname"  formControlName="fname"  placeholder="First Name" required>
                                            </div>
                                            <div *ngIf="angForm.controls['fname'].invalid && (angForm.controls['fname'].dirty || angForm.controls['fname'].touched)" class="alert alert-danger">
                                                <div *ngIf="angForm.controls['fname'].errors.required">
                                                  First Name should not be blank !
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="name">Last Name</label>
                                                <input type="text" class="form-control" id="lname"  formControlName="lname"  placeholder="Last Name" required>
                                            </div>
                                            <div *ngIf="angForm.controls['lname'].invalid && (angForm.controls['lname'].dirty || angForm.controls['lname'].touched)" class="alert alert-danger">
                                                <div *ngIf="angForm.controls['lname'].errors.required">
                                                  Last Name should not be blank !
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="address">Address</label>
                                                <input type="text" class="form-control" id="address" formControlName="address" placeholder="Address Line 1" required>
                                            </div>                            
                                            <div *ngIf="angForm.controls['address'].invalid && (angForm.controls['address'].dirty || angForm.controls['address'].touched)" class="alert alert-danger">
                                                <div *ngIf="angForm.controls['address'].errors.required">
                                                  Address should not be blank !
                                                </div>
                                            </div>
                                        </div>
                
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="email">Address Line 2</label>
                                                <input type="text" class="form-control" id="address2" formControlName="address2" placeholder="Address Line 2" required>
                                            </div>                            
                                            <div *ngIf="angForm.controls['address2'].invalid && (angForm.controls['address2'].dirty || angForm.controls['address2'].touched)" class="alert alert-danger">
                                                <div *ngIf="angForm.controls['address2'].errors.required">
                                                  Address should not be blank !
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="message">Country</label>
                                                <select class="form-control" id="Country" formControlName="Country">
                                                  </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="message">State</label>
                                                <select class="form-control" id="State" formControlName="State"> 
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="message">City</label>
                                                <select class="form-control" id="city" formControlName="city"> 
                                                  </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="message">Pincode</label>
                                                <input type="number" class="form-control" id="pincode" formControlName="pincode" placeholder="Pincode" required>
                                            <div *ngIf="angForm.controls['pincode'].invalid && (angForm.controls['pincode'].dirty || angForm.controls['pincode'].touched)" class="alert alert-danger">
                                                <div *ngIf="angForm.controls['pincode'].errors.required">
                                                  Pincode is not valid !
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="mobile">Mobile No</label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                      <span class="input-group-text">+ 91</span>
                                                    </div>
                                                    <input type="number" class="form-control" formControlName="mobileNo" minlength="8" max="10" placeholder="Mobile No" aria-label="Username" aria-describedby="basic-addon1">
                                                  </div>                                  
                                            <div *ngIf="angForm.controls['mobileNo'].invalid && (angForm.controls['mobileNo'].dirty || angForm.controls['mobileNo'].touched)" class="alert alert-danger">
                                                <div *ngIf="angForm.controls['mobileNo'].errors.required">
                                                  Mobile no. is not valid !
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="email">Email</label>
                                                <input type="email" class="form-control" id="email" formControlName="email" placeholder="Email" required>
                                            </div>                                
                                            <div *ngIf="angForm.controls['email'].invalid && (angForm.controls['email'].dirty || angForm.controls['email'].touched)" class="alert alert-danger">
                                                <div *ngIf="angForm.controls['email'].errors.required">
                                                  Email is not valid !
                                                </div>
                                            </div>
                                        </div>                       
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">         
                                            <div class="mx-auto">
                                                <button type="submit"  [disabled]="angForm.pristine || angForm.invalid"  class="btn btn-success text-right" (click)="onSubmit()">Add Address</button>
                                            </div>
                                        </div>
                                    </div> 
                                     
                                </div>
                            </form>
                                <!-- End -->
                
                
                             
                        </div>  
                        

                    <!--  <div class="col-md-12">                    
                          <label for="dhoop">Amrutam Desi Gir Cow Panchagavya Deep & Sambrani Dhoop</label>
                          <div class="col-md-12"><img src="dhoo.gif" class="img-fluid animated-img"></div>
                        
                        
                      </div> -->
                      <div id="tempHtml" class="template" ></div>
                       
                       
                      <div class="col-md-12 col-sm-12 mt-4">
                        <button class="btn btn-success" (click)="viewCart()" >View Cart</button>
                    </div>
                       
                    </div>

                    </form>
                </div>
            </div>
        </div> 
            
    </div>
  
