import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../shared/products.service';

@Component({
  selector: 'app-myorder',
  templateUrl: './myorder.component.html',
  styleUrls: ['./myorder.component.css']
})
export class MyorderComponent implements OnInit {
  public id: string = "0";

  constructor( public service : ProductsService) { }

  ngOnInit(): void {
    if(localStorage.getItem("userId") != null){
      this.id = localStorage.getItem("userId");
      this.service.GetCartCount(localStorage.getItem("userId")).subscribe((res:any) =>{
        (<HTMLInputElement>document.getElementById('lblCart')).innerHTML = String(res);
        (<HTMLInputElement>document.getElementById('lblCartM')).innerHTML = String(res);
      })
    }
    this.GetMyOrder();
  }

  GetMyOrder(){
    this.service.GetCustomerOrder(this.id);
  }

}
