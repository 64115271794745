import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../shared/products.service';
import { Users } from '../shared/users.model';
import { FormGroup,  FormBuilder,  Validators, CheckboxRequiredValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAddress } from '../shared/user-address.model';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public id: string = "0"; 
  listUser : Users;
  angForm: FormGroup; 
  angFormReg: FormGroup; 
  private msg : String;
  private captchaString : string;

  constructor(private route: ActivatedRoute, private router: Router, public service : ProductsService,private fb: FormBuilder) { }

  ngOnInit(): void { 
    this.msg = this.route.snapshot.queryParamMap.get('msg');
     
    if(this.msg == 'signOut'){
      localStorage.removeItem("userId");
      localStorage.clear();
     this.router.navigate(['login']).then(() => {
        window.location.reload();
      });
    } 
     
    this.createForm();
//    this.refreshCaptcha();
    if(localStorage.getItem("userId") != null){ 
      this.service.GetCartCount(localStorage.getItem("userId")).subscribe((res:any) =>{
        (<HTMLInputElement>document.getElementById('lblCart')).innerHTML = String(res);
        (<HTMLInputElement>document.getElementById('lblCartM')).innerHTML = String(res);
      })
    }
  }
  createForm() {
    this.angForm = this.fb.group({
      username: ['',[Validators.required,  Validators.max(9999999999)] ],
      pass: ['', Validators.required ] 
    });
    this.angFormReg = this.fb.group({
      regUsername: ['', Validators.required ],
      regMobile: ['',  [Validators.required,  Validators.max(9999999999)] ] ,
      regPass: ['', Validators.required ]
    });
      //regEmail: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")] ] ,
  }
  checkUser()
  {
    (<HTMLLabelElement>document.getElementById('lblErrorReg')).innerHTML ='';
    (<HTMLLabelElement>document.getElementById('lblError')).innerHTML = '';
    if (this.angForm.invalid) { 
      return;
    }
      this.service.CheckUser((<HTMLInputElement>document.getElementById('username')).value,(<HTMLInputElement>document.getElementById('pass')).value).subscribe((data: any)=>{
        this.listUser = data as Users;
        if(this.listUser[0].userMessage == 'success'){
          this.id = this.listUser[0].userId;
          localStorage.setItem("userId", this.id);
          location.href = 'productlist';
        } else{
          (<HTMLLabelElement>document.getElementById('lblError')).innerHTML = this.listUser[0].userMessage;
        }
      }); 
  }
  refreshCaptcha(){
   this.captchaString =this.makeRandom();
   (<HTMLLabelElement>document.getElementById('useCaptcha')).innerHTML = this.captchaString;
  }

   makeRandom() {
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lengthOfCode= 4;
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
      return text;
  }

  checkRegisterUser()
  {
    (<HTMLLabelElement>document.getElementById('lblErrorReg')).innerHTML ='';
    (<HTMLLabelElement>document.getElementById('lblError')).innerHTML = '';
    if (this.angFormReg.invalid) { 
      return;
    } 
    this.service.CheckDuplicateUser((<HTMLInputElement>document.getElementById('regMobile')).value).subscribe((data: any)=>{
      this.listUser = data as Users; 
      if(this.listUser[0].userMessage == 'no'){ 
        this.service.AddCustomer((<HTMLInputElement>document.getElementById('regUsername')).value,
        (<HTMLInputElement>document.getElementById('regEmail')).value ,
        (<HTMLInputElement>document.getElementById('regMobile')).value,
        (<HTMLInputElement>document.getElementById('regPass')).value
        ).subscribe(
          res => { 
           if(Number(res) > 0){
            (<HTMLInputElement>document.getElementById('regUsername')).value = '';
            (<HTMLInputElement>document.getElementById('regEmail')).value = '';
            (<HTMLInputElement>document.getElementById('regMobile')).value = '';
            (<HTMLInputElement>document.getElementById('regPass')).value = '';
            (<HTMLLabelElement>document.getElementById('lblError')).innerHTML = 'User Register Succesfully ! Please Login !';
            (<HTMLHRElement>document.getElementById('profile-tab')).click();
           }
        });
      } else{ 
          (<HTMLLabelElement>document.getElementById('lblErrorReg')).innerHTML = this.listUser[0].userMessage;
      }
    }); 
  }

}
