import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../shared/products.service';
import { Router } from '@angular/router';
import { CartDetails } from '../shared/cart-details.model';
import { UserAddress } from '../shared/user-address.model'; 
import { FormGroup,  FormBuilder,  Validators, CheckboxRequiredValidator } from '@angular/forms';

 

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  listCart : CartDetails[];
  listAddress : UserAddress[]; 
  angForm: FormGroup; 
  selectedAddress: number;

  constructor(public service : ProductsService, private router: Router,private fb: FormBuilder) { 
    this.createForm();
  }

  ngOnInit(): void {
    if(localStorage.getItem("userId") != null){ 
      this.refreshData();
      this.GetCountry();
      this.GetAddress();
      this.GetCartCount();
    }else
    {
      this.router.navigate(['home']);
    }
  }

  GetCartCount(){    
    this.service.GetCartCount(localStorage.getItem("userId")).subscribe((res:any) =>{
      (<HTMLInputElement>document.getElementById('lblCart')).innerHTML = String(res);
      (<HTMLInputElement>document.getElementById('lblCartM')).innerHTML = String(res);
    })
  }

  GetAddress(){
    this.service.GetAddress(localStorage.getItem("userId")).subscribe((data: any)=>{
      if(data.length > 0){
      this.selectedAddress = data[0].aId;
       this.listAddress = data as  UserAddress[];
       if(data.length == 3){
        (<HTMLButtonElement>document.getElementById('btnAddAddress')).style.display = 'none';
       }else{
        (<HTMLButtonElement>document.getElementById('btnAddAddress')).removeAttribute("style");
       }
      }else{
        this.showAddress();
      }
    });  
  }

  onSelectionChange(selectedValue : number ){
     this.selectedAddress = selectedValue;
  }
  
DeleteAddress(){
  this.service.DeleteAddress(this.selectedAddress).subscribe(
    res => { 
     this.GetAddress();
  });  
}

  GetCountry(){
    let cId : number = 0;
    this.service.GetCountryCity(0).subscribe((data: any)=>{
      for(let i =0; i  < data.length;i++){
        if(i == 0){
          this.GetState(data[i].id);
        }
        (<HTMLInputElement>document.getElementById('Country')).innerHTML += '<option value='+ data[i].id +'>'+ data[i].name +'</option>';
      } 
    });  
  }

  GetState(id : number){ 
    this.service.GetCountryCity(id).subscribe((stateData: any)=>{
      for(let i =0; i  < stateData.length;i++){
        if(i == 0){ 
         this.GetCity(stateData[i].id);
        }
        (<HTMLInputElement>document.getElementById('State')).innerHTML += '<option value='+ stateData[i].id +'>'+ stateData[i].name +'</option>';
      } 
    });  
  }

  GetCity(id : number){
    this.service.GetCountryCity(id).subscribe((data: any)=>{
      for(let i =0; i  < data.length;i++){
        (<HTMLInputElement>document.getElementById('city')).innerHTML += '<option value='+ data[i].id +'>'+ data[i].name +'</option>';
      } 
    });  
  }

  createForm() {
    this.angForm = this.fb.group({
       fname: ['', Validators.required ],
       lname: ['', Validators.required ],
       address: ['', Validators.required ],
       address2: ['', Validators.required ],
       pincode: ['', Validators.required],
       mobileNo: ['', Validators.required],
       email: ['', Validators.required ],
       Country:[''],
       State: [''],
       city: [''],
       userId: ['']
    });
  }

  onSubmit() {
    if (this.angForm.invalid) { 
        return;
    }
    this.angForm.value.Country = (<HTMLInputElement>document.getElementById('Country')).value;
    this.angForm.value.State = (<HTMLInputElement>document.getElementById('State')).value;
    this.angForm.value.city = (<HTMLInputElement>document.getElementById('city')).value;
    this.angForm.value.userId = localStorage.getItem("userId");
     this.service.AddAddress(this.angForm.value).subscribe(
      res => { 
        this.selectedAddress = Number(res);
        this.placeOrder();
    });
}

  showAddress(){
    (<HTMLDivElement>document.getElementById('divAddress')).style.display = 'none';
    (<HTMLDivElement>document.getElementById('divAddForm')).style.display = 'block';
  }
 
  refreshData(){
    this.service.GetCart(String(localStorage.getItem("userId"))).subscribe((response: any)=>{
    this.listCart = response as CartDetails[]; 
    this.fnTotalPrice(); 
  });
}

placeOrder(){ 
    this.service.AddOrder(this.selectedAddress, Number(localStorage.getItem("userId")), '0').subscribe(
    res => {  
      if (res[0].billId > 0){
        location.href = 'placeorder/' + res[0].billNumber;
    }
  }); 
}

  fnTotalPrice()  {
    let total : number = 0;
    for(let i =0;i < this.listCart.length;i++){ 
      total +=  Number(this.listCart[i]["Price"])  * Number(this.listCart[i]["qty"]);
    } 
    (<HTMLLabelElement>document.getElementById('lblTotal')).innerHTML = String(total); 
    this.GetDisc(total);
  }

  GetDisc(total : number){
    this.service.GetDiscount(String(localStorage.getItem("userId"))).subscribe((response: any)=>{
      if(response.disc == ''){
        (<HTMLInputElement>document.getElementById('divDisc')).style.display = "none";        
        (<HTMLInputElement>document.getElementById('divFTotal')).style.display = "none";
      }else{
        (<HTMLInputElement>document.getElementById('lblDisc')).innerHTML = String(response.disc); 
          (<HTMLInputElement>document.getElementById('lblFTotal')).innerHTML = String(total - Number(response.disc)); 
      }
    });
  }

}
 