import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/shared/products.service';
import { ActivatedRoute, Router } from '@angular/router'; 
import { Users } from '../shared/users.model';
import { IndexService } from '../shared/index.service';
 
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html'//,
  //styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit { 
  public id: string = "0";
  public catId : string;
  listUser : Users;
    
  constructor(private router: Router,private route: ActivatedRoute, public service : ProductsService, public serviceIndex : IndexService) { 
   
  }
 

  ngOnInit(): void {
    if(localStorage.getItem("userId") != null){
      this.id = localStorage.getItem("userId");
      this.viewCart();
    }
   this.catId = this.route.snapshot.paramMap.get('id') 
   this.refreshData();
   //this.serviceIndex.GetMenu(0);  
  }


  refreshData(){
    this.service.GetProduct(this.catId, this.id,"0","0");
  }

  checkReload : number = 0;
  fnfillSize(size : string, sizeId : string, ind : number, catSizeId : number ){ 
    if(ind == this.checkReload){
      let sizeArray = size.split(',')
      let sizeIdArray = sizeId.split(',')
      let strHtml : string;
      let strSpanHtml : string;
      
      if(sizeArray.length > 1){
        for(let i =0; i  < sizeArray.length;i++){
          strHtml += '<option value='+ sizeIdArray[i] +'>'+ sizeArray[i] +'</option>';
        }  
          (<HTMLInputElement>document.getElementById('spanSize' + ind)).style.display = 'none';
      }else{ 
        //strSpanHtml = '<span>'+ sizeArray[0] +'</span>';
        strHtml = '<option value='+ sizeIdArray[0] +'>'+ sizeArray[0] +'</option>';
        (<HTMLInputElement>document.getElementById('spanSize' + ind)).innerHTML = sizeArray[0];
        (<HTMLInputElement>document.getElementById('drpSize' + ind)).style.display = 'none'; 
      }
      (<HTMLInputElement>document.getElementById('drpSize' + ind)).innerHTML = strHtml;
     if(catSizeId > 0){
      (<HTMLInputElement>document.getElementById('drpSize' + ind)).value = String(catSizeId);
     }
      this.fnGetPrice(ind);
     this.checkReload=ind + 1; 
    }
  }

  fnSizeChange(index : number){
    this.fnGetPrice(index);
    (<HTMLInputElement>document.getElementById('name' + index)).value = "1" ;
    this.onMinus(index);
  }  

  fnGetPrice(index : number){
    this.service.GetPriceById((<HTMLInputElement>document.getElementById('drpSize' + index)).value).subscribe((data: any)=>{
     (<HTMLInputElement>document.getElementById('sPrice' + index)).innerHTML = data; 
   });  
 }

  onMinus(index: number){ 
    let prd =Number((<HTMLInputElement>document.getElementById('name' + index)).value);
    if(prd > 0){
      (<HTMLInputElement>document.getElementById('name' + index)).value = String(prd - 1);
      this.service.listProduct[index].qty =  prd - 1;
      if(prd == 1){
        (<HTMLInputElement>document.getElementById('divAdd' + index)).style.display = 'none';
        (<HTMLInputElement>document.getElementById('btnAdd' + index)).style.display = 'block';
      }
      this.addCart(Number((<HTMLInputElement>document.getElementById('lblProductId' + index)).innerHTML)
      ,Number((<HTMLInputElement>document.getElementById('name' + index)).value), 
      (<HTMLInputElement>document.getElementById('drpSize' + index)).value);
    }
    //this.GetCartCount();
  }
  onPlus(index: number){ 
    if(localStorage.getItem("userId") == null){
      (<HTMLHRElement>document.getElementById('loginID')).click();
    }
    else
    {
      let prd = Number((<HTMLInputElement>document.getElementById('name' + index)).value);
      if(prd == 0){ 
        (<HTMLInputElement>document.getElementById('divAdd' + index)).style.display = 'block';
        (<HTMLInputElement>document.getElementById('btnAdd' + index)).style.display = 'none';
      }
      (<HTMLInputElement>document.getElementById('name' + index)).value = String(prd +1);
      this.service.listProduct[index].qty =  prd + 1;
      
      this.addCart( Number((<HTMLInputElement>document.getElementById('lblProductId' + index)).innerHTML)
      ,Number((<HTMLInputElement>document.getElementById('name' + index)).value), 
      (<HTMLInputElement>document.getElementById('drpSize' + index)).value
      );
    }
    //this.GetCartCount();
  }
  addCart( prodId : number, qty : number , size : string ){
     //console.log(size);
       this.service.AddToCart(Number(this.id)  , prodId ,qty, size).subscribe(
       res => { 
        this.viewCart();
     }); 
   }

   checkUser()
   {
     if((<HTMLInputElement>document.getElementById('username')).value == ''){

     }else if ((<HTMLInputElement>document.getElementById('pass')).value == '') {

     }else{
        this.service.CheckUser((<HTMLInputElement>document.getElementById('username')).value,(<HTMLInputElement>document.getElementById('pass')).value).subscribe((data: any)=>{
         this.listUser = data as Users;
          if(this.listUser[0].userMessage == 'success'){
            this.id = this.listUser[0].userId;
            localStorage.setItem("userId", this.id);
            (<HTMLButtonElement>document.getElementById('btnChkCancel')).click();
          } else{

          }
      }); 
    }
   }

  viewCart(){    
    this.service.GetCartCount(localStorage.getItem("userId")).subscribe((res:any) =>{
      (<HTMLInputElement>document.getElementById('lblCart')).innerHTML = String(res);
      (<HTMLInputElement>document.getElementById('lblCartM')).innerHTML = String(res);
    })
  }

   fnOpenFilter(){
    if((<HTMLDivElement>document.getElementById('filterPopup')).style.display == 'none'){
      (<HTMLDivElement>document.getElementById('filterPopup')).style.display = 'block';
    }else{
      (<HTMLDivElement>document.getElementById('filterPopup')).style.display = 'none';
    }
   }

   onBuyNow(){
    this.router.navigate(['productlist']);
   }

}
