<div class="yellow-bg  margin-from-nav">
    <div class="container-fluid">
    <div class="row">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="home">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Login</li>
        </ol>
    </div>
  </div>
  </div>

  <div class="container-fluid cart-page">  
    <div class="border pad-10 mb-5">

<!-- Modal -->
        <div class="modal-body">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Sign In </a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">New Customer</a>
            </li>        
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab"> 
                <form  [formGroup]="angForm" >
                <div class="row">
                    <div class="col-md-12 mt-2">
                        <div class="form-group">
                          <div class="row">
                            <div class="col-md-12">
                              <label id="lblError" class="errorText"></label>
                            </div> 
                          </div>  
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="form-group">
                          <div class="row">
                            <div class="col-md-2">
                              <label for="name">Mobile No.</label>
                            </div>
                            <div class="col-md-9">
                              <input type="number" class="form-control" id="username" maxlength="10"   formControlName="username"  placeholder="Enter Mobile No." required>
                            </div>
                            <div *ngIf="angForm.controls['username'].invalid && (angForm.controls['username'].dirty || angForm.controls['username'].touched)" class="alert alert-danger">
                                <div *ngIf="angForm.controls['username'].errors.required">
                                  Mobile NO. should not be blank !
                                </div>
                                <div *ngIf="angFormReg.controls['regMobile'].errors.max">
                                  Please Enter Valid Mobile No. !
                                </div> 
                            </div>
                          </div>  
                        </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-2">
                            <label  for="pass"  >Password</label>
                           <!-- <label id="useCaptcha" for="name" class="captcha"></label>&nbsp;
                            <button type="button" (click)="refreshCaptcha()" class="btn btn-success"><i class="fa fa-refresh" ></i></button> -->
                          </div>
                          <div class="col-md-9">
                            <input type="password" class="form-control" id="pass" maxlength="10"  formControlName="pass"  placeholder="Password" required>
                          </div>
                          <div *ngIf="angForm.controls['pass'].invalid && (angForm.controls['pass'].dirty || angForm.controls['pass'].touched)" class="alert alert-danger">
                              <div *ngIf="angForm.controls['pass'].errors.required">
                                Password should not be blank !
                              </div>
                          </div>
                        </div>  
                      </div>
                  </div>
                </div>
                <div class="row">  
                    <div class="col-md-12 text-left">
                      <div class="btn-inline">
                        <button type="submit" class="btn btn-success" [disabled]="angForm.pristine || angForm.invalid" (click)="checkUser()">Submit</button>
                       <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button> --> 
                      </div> 
                    </div>                    
                  </div>
                </form> 
            </div>
            <div class="tab-pane fade" id="home" role="tabpanel" aria-labelledby="home-tab">  
                <form  [formGroup]="angFormReg" >
                  <div class="col-md-12 mt-2">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-12">
                          <label id="lblErrorReg" class="errorText"></label>
                        </div> 
                      </div>  
                    </div>
                </div>
              <div class="row ">
                  <div class="col-md-12 mt-2">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-3">
                            <label for="name">Your Name</label>
                          </div>
                          <div class="col-md-9">
                            <input type="text" class="form-control" id="regUsername" formControlName="regUsername"   placeholder="Your Name" required>
                            <div *ngIf="angFormReg.controls['regUsername'].invalid && (angFormReg.controls['regUsername'].dirty || angFormReg.controls['regUsername'].touched)" class="alert alert-danger">
                              <div *ngIf="angFormReg.controls['regUsername'].errors.required">
                                Your Name should not be blank !
                              </div>
                          </div>
                          </div>
                        </div>  
                      </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="name">Mobile No. / Username</label>
                        </div>
                        <div class="col-md-9">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text">+ 91</span>
                              <input type="number" class="form-control" id="regMobile" maxlength="10" placeholder="Mobile No"  formControlName="regMobile"  >
                            </div>
                            
                            <div *ngIf="angFormReg.controls['regMobile'].invalid && (angFormReg.controls['regMobile'].dirty || angFormReg.controls['regMobile'].touched)" class="alert alert-danger">
                              <div *ngIf="angFormReg.controls['regMobile'].errors.required">
                                Mobile No should be Numeric !
                              </div>  
                              <div *ngIf="angFormReg.controls['regMobile'].errors.max">
                                Please Enter Valid Mobile No. !
                              </div> 
                          </div>
                          </div>
                        </div>
                      </div>  
                    </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-3">
                        <label for="name">Email Id (Not Mandatory)</label>
                      </div>
                      <div class="col-md-9">
                        <input type="email" class="form-control" id="regEmail"  placeholder="Email Id" >
                      
                      </div>
                    </div>  
                  </div>
              </div>
              <div class="col-md-12 mt-2">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="name">Password</label>
                        </div>
                        <div class="col-md-9">
                          <input type="password" class="form-control" id="regPass" maxlength="10"  formControlName="regPass"  placeholder="Passwords must be at least 6 characters." required>
                          <div *ngIf="angFormReg.controls['regPass'].invalid && (angFormReg.controls['regPass'].dirty || angFormReg.controls['regPass'].touched)" class="alert alert-danger">
                            <div *ngIf="angFormReg.controls['regPass'].errors.required">
                              Password should not be blank !
                            </div> 
                        </div>
                        </div>
                      </div>  
                    </div>
                </div>
              </div>
        <div class="row">  
            <div class="col-md-12 text-left">
              <div class="btn-inline">
                <button type="submit" class="btn btn-success"  [disabled]="angFormReg.pristine || angFormReg.invalid" (click)="checkRegisterUser()">Submit</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>  
              </div> 
            </div>                    
          </div>
        </form>
            </div>
            
         
          </div>
        </div>
    </div>  
</div>  
