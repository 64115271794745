<div class="yellow-bg margin-from-nav">
    <div class="container-fluid">
    <div class="row">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="home">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">My Order</li>
        </ol>
    </div>
  </div>
  </div>

  <div class="container-fluid cart-page">  
    <div class="border pad-10 mb-5">
        <div class="modal-body">
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab"> 

            <div class="row">
                <!--Middle Part Start-->
                <div id="content" class="col-sm-12">
                    
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                   
                                    <td class="text-center">Order ID</td>
                                    <td class="text-center">Qty</td>
                                    <td class="text-center">Status</td>
                                    <td class="text-center">Date Added</td>
                                    <td class="text-right">Total</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody  *ngFor="let ord of service.listOrder;  let in=index">
                                <tr>
                                    
                                    <td class="text-center">{{ord.billNumber}}</td>
                                    <td class="text-center">{{ord.productCnt}}</td>
                                    <td class="text-center">{{ord.deliveryStatus}}</td>
                                    <td class="text-center">{{ord.orderDate | date: 'dd-MMM-yyyy' }}</td>
                                    <td class="text-right"><i class="fa fa-rupee"></i>&nbsp;{{ord.amount}}</td>
                                    <td class="text-center"><a class="btn btn-success" title="" data-toggle="tooltip"
                                            href="Orderinfo/{{ord.billId}}" data-original-title="View"><i
                                                class="fa fa-eye"></i></a>
                                    </td>
                                </tr> 
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

                </div>
            </div>         
        </div> 
    </div>
</div> 
