import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '../shared/products.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  aId : string;
  constructor(private route: ActivatedRoute,public service : ProductsService, private router: Router) { }

  ngOnInit(): void { 
    window.addEventListener("keyup", this.disableF5);
    window.addEventListener("keydown", this.disableF5);
   this.DisplayMessage();
   
    
  }
  DisplayMessage(){

    if(this.route.snapshot.paramMap.get('aId') == '0'){
      this.aId = localStorage.getItem("ordNo");
    }else{
      this.aId = this.route.snapshot.paramMap.get('aId');
    }
   // console.log('testing id - ' + this.aId);
    if(this.route.snapshot.paramMap.get('status')  == 'success'){

      this.service.OrderStatus(this.route.snapshot.paramMap.get('id'), "1" ,this.aId).subscribe(
        res => { 
          (<HTMLLabelElement>document.getElementById('lblMessage')).innerHTML =  this.aId; 
          (<HTMLDivElement>document.getElementById('success')).style.display = 'block';
          (<HTMLDivElement>document.getElementById('failed')).style.display = 'none';
        });
    }else if(this.route.snapshot.paramMap.get('status')  == 'failure') {
        this.service.OrderStatus(this.route.snapshot.paramMap.get('id'), "3" ,this.aId).subscribe(
          res => { 
        (<HTMLDivElement>document.getElementById('success')).style.display = 'none';
        (<HTMLDivElement>document.getElementById('failed')).style.display = 'block';
      });
    }
    
    localStorage.removeItem("ordNo");
  }


 disableF5(e) {
    if ((e.which || e.keyCode) == 116) e.preventDefault(); 
 };

 

}
