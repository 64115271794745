import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RazorService {

 readonly rootURL ="https://service.aranyamamrutam.com/api"; 
  // readonly rootURL ="http://localhost:2853/api"; 
  
   
  constructor(private http : HttpClient) { }

  GetToken(amount : number,orderId : string){ 
   let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8'}) };
   return this.http.post(this.rootURL+'/token/?amount='+ amount +'&orderId='+ orderId, options);    
 }
}
