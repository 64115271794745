

<div class="yellow-bg margin-from-nav">
  <div class="container-fluid">
  <div class="row">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Product Detail</li>
      </ol>
  </div>
</div>
</div>

<div class="container-fluid">
  <div class="row product-page">
      <div class="col-md-4"   *ngFor="let prd of service.listProduct;  let in=index">
         <div class="card">
             <div class="row ml-0 mr-0">
                 <div class="col-md-4 col-sm-4 col-4  align-md-self-center">
                  <img class="card-img-top img-fluid" src="{{service.imgPath}}{{prd.image}}" alt="">
                 </div>
                 <div class="col-md-8 col-sm-8 col-8 align-self-center">
                  <h5>{{prd.pTitle}}</h5>
                  <p [innerHTML]="prd.sDesc"> </p>
                 <!-- <div class="pull-left">
                      <p><strong>Quantity:</strong><select id="drpSize{{in}}" #drpSize{in} name="drpSize{{in}}" (change)="fnSizeChange(in)"></select><sapn  #spanSize{in}  id="spanSize{{in}}" name="spanSize{{in}}" ></sapn>
                          <template id="templateQty" >{{fnfillSize(prd.size,prd.sizeId,in, prd.cartSizeId)}}</template></p>
                      <p><strong>Price: </strong><i class="fa fa-rupee"></i> <span #sPrice{in} id="sPrice{{in}}" name="sPrice{{in}}">{{prd.salePrice}}</span></p>
                  </div>-->
                 <div class="pull-right">
                     <div class="btn-box text-right">
                      <button class="btn btn-success add-btn"  #btnAdd{in}  id="btnAdd{{in}}" name="btnAdd{{in}}" (click)="onBuyNow(in)" >Buy Now</button>
                    <!--  <button class="btn btn-success add-btn"  #btnAdd{in}  id="btnAdd{{in}}" name="btnAdd{{in}}" (click)="onPlus(in)" [style.display]="(prd.qty != 0) ? 'none' : 'block' ">Add</button>
                         <div class="qty-box" #divAdd{in}  id="divAdd{{in}}" name="divAdd{{in}}"  [style.display]="(prd.qty == 0) ? 'none' : 'block' ">
                              <div class="qty">
                                  <span class="minus bg-success"  (click)="onMinus(in)"><i class="fa fa-minus"></i></span>
                                  <input type="text"  #name{in} id="name{{in}}" name="name{{in}}" class="form-control count" name="qty"  ngModel [(ngModel)]="prd.qty" value="{{prd.qty}}" >
                                  <span class="plus bg-success"  (click)="onPlus(in)"><i class="fa fa-plus"></i></span>
                                  <span #lblProductId{in}  id="lblProductId{{in}}" name="lblProductId{{in}}" style="display: none;" >{{prd.productId}}</span>
                              </div>
                         </div> -->
                     </div>                
                 </div>
                 </div>
             </div>
         </div>
      </div>         
  </div>    
</div>




<!-- Modal -->
<div class="modal fade" id="loginPopup" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Login Here</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-6">
                          <label for="name">Username</label>
                        </div>
                        <div class="col-md-6">
                          <input type="text" class="form-control" id="username"  placeholder="Username" required>
                        </div>
                      </div>  
                    </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-6">
                        <label for="name">Password</label>
                      </div>
                      <div class="col-md-6">
                        <input type="text" class="form-control" id="pass"  placeholder="Password" required>
                      </div>
                    </div>  
                  </div>
              </div>
            </div>
            </form>
        </div>
        <div class="row">  
          <div class="col-md-12 text-center">
            <div class="btn-inline">
              <button  #btnChkUser  id="btnChkUser" name="btnChkUser" class="btn btn-success" (click)="checkUser()">Submit</button>
              <button type="button" id="btnChkCancel" name="btnChkCancel" class="btn btn-secondary" data-dismiss="modal">Cancel</button>    
            </div>  
          </div>                    
        </div>
      </div>
    </div>
  </div>
  
  
  <div class="product-filter"  (click)="fnOpenFilter()">
    <!--<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="filter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-filter fa-w-16 fa-fw fa-2x"><path fill="currentColor" d="M463.952 0H48.057C5.419 0-16.094 51.731 14.116 81.941L176 243.882V416c0 15.108 7.113 29.335 19.2 40l64 47.066c31.273 21.855 76.8 1.538 76.8-38.4V243.882L497.893 81.941C528.042 51.792 506.675 0 463.952 0zM288 224v240l-64-48V224L48 48h416L288 224z" class=""></path></svg>-->
    <span>Category</span>
    <i class="fa fa-filter"></i>
</div>
<div id="filterPopup" class="product-filter-popup" >
    <div class="product-filter-items"  *ngFor="let prdM of serviceIndex.listMenu;  let in=index"> 
        <a class="dropdown-item" href="products/{{prdM.categoryId}}">{{prdM.category}}</a>
    </div>
  
</div>
 