 
<div class="banner margin-from-nav">
<!-- <img src="assets/images/banner.jpg" class="img-fluid" />--> 
<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
  <ol id="divCnt" class="carousel-indicators">
     
  </ol>
  <div id="divBanner" class="carousel-inner">
     
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
</div>
 
  <div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <button class="btn btn-success mkt-btn" #btnweekMkt  id="btnweekMkt" name="btnweekMkt" (click)="WeekMkt()" >Weekly Market Order Form</button>
    </div>  
  </div>
</div> 

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h2 class="text-center">About Us
        <span class="heading-style"><i></i><i></i><i></i></span>
      </h2>
      <div id="divContent" >
        
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h2 class="text-center">Shop New Arrivals
        <span class="heading-style"><i></i><i></i><i></i></span>
      </h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="new-arrival-tab" data-toggle="tab" href="#new-arrival" role="tab" aria-controls="nav-home" aria-selected="true">New Arrivals</a>
          <a class="nav-item nav-link" id="top-seller-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Top Sellers</a>
        </div>
      </nav>
      <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
        <div class="tab-pane fade show active" id="new-arrival" role="tabpanel" aria-labelledby="new-arrival-tab">
          <div class="row">
            <div class="col-md-3 col-sm-6 col-12 tabrow"  *ngFor="let prd of service.listProduct;  let in=index">   
              <div class="text-center">
              <div class="card">
                <img class="card-img-top img-fluid" src="{{service.imgPath}}{{prd.image}}" alt="">
                <h5>{{prd.pTitle}}</h5>
                <p [innerHTML]="prd.sDesc"></p>
              <!-- <div class="pull-left">
                  <p><strong>Quantity:</strong><select id="drpSize{{in}}" #drpSize{in} name="drpSize{{in}}" (change)="fnSizeChange(in, '')"></select><sapn  #spanSize{in}  id="spanSize{{in}}" name="spanSize{{in}}" ></sapn>
                      <template id="templateQty" >{{fnfillSize(prd.size,prd.sizeId,in, prd.cartSizeId, '')}}</template></p>
                  <p><strong>Price: </strong><i class="fa fa-rupee"></i> <span #sPrice{in} id="sPrice{{in}}" name="sPrice{{in}}">{{prd.salePrice}}</span></p>
              </div> --> 
              <div class="pull-right">
                <div class="btn-box text-right">
                  <button class="btn btn-success add-btn"  #btnAdd{in}  id="btnAdd{{in}}" name="btnAdd{{in}}" (click)="onBuyNow(in)" >Buy Now</button>
               <!--   <button class="btn btn-success add-btn"  #btnAdd{in}  id="btnAdd{{in}}" name="btnAdd{{in}}" (click)="onPlus(in, '')" [style.display]="(prd.qty != 0) ? 'none' : 'block' ">Add</button>
                  <div class="qty-box" #divAdd{in}  id="divAdd{{in}}" name="divAdd{{in}}"  [style.display]="(prd.qty == 0) ? 'none' : 'block' ">
                   <div class="qty">
                       <span class="minus bg-success"  (click)="onMinus(in, '')"><i class="fa fa-minus"></i></span>
                       <input type="text"  #name{in} id="name{{in}}" name="name{{in}}" class="form-control count" name="qty"  ngModel [(ngModel)]="prd.qty" value="{{prd.qty}}" >
                       <span class="plus bg-success"  (click)="onPlus(in, '')"><i class="fa fa-plus"></i></span>
                       <span #lblProductId{in}  id="lblProductId{{in}}" name="lblProductId{{in}}" style="display: none;" >{{prd.productId}}</span>
                   </div>
                  </div> --> 
                </div>  
              </div>
              </div>  
            </div>
            </div> 

          </div>
        </div>
        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="top-seller-tab">
         <div class="row">
         
          <div class="col-md-3 col-sm-6 col-12"  *ngFor="let prdSell of service.listTopSeller;  let in1=index">   
            <div class="text-center">
            <div class="card">
              <img class="card-img-top img-fluid" src="{{service.imgPath}}{{prdSell.image}}" alt="">
              <h5>{{prdSell.pTitle}}</h5>
              <p [innerHTML]="prdSell.sDesc"></p>
             <!-- <div class="pull-left">
                <p><strong>Quantity:</strong><select id="drpSizeSell{{in1}}" #drpSizeSell{in} name="drpSizeSell{{in1}}" (change)="fnSizeChange(in1, 'Sell')"></select><sapn  #spanSizeSell{in1}  id="spanSizeSell{{in1}}" name="spanSizeSell{{in1}}" ></sapn>
                    <template id="templateQty" >{{fnfillSize(prdSell.size,prdSell.sizeId,in1, prdSell.cartSizeId , 'Sell')}}</template></p>
                <p><strong>Price: </strong><i class="fa fa-rupee"></i> <span #sPriceSell{in1} id="sPriceSell{{in1}}" name="sPriceSell{{in1}}">{{prdSell.salePrice}}</span></p>
            </div>-->
            <div class="pull-right">
              <div class="btn-box text-right">
                <button class="btn btn-success add-btn"  #btnAdd{in}  id="btnAdd{{in}}" name="btnAdd{{in}}" (click)="onBuyNow(in)" >Buy Now</button>
             <!--   <button class="btn btn-success add-btn"  #btnAddSell{in1}  id="btnAddSell{{in1}}" name="btnAddSell{{in1}}" (click)="onPlus(in1, 'Sell')" [style.display]="(prdSell.qty != 0) ? 'none' : 'block' ">Add</button>
                <div class="qty-box" #divAddSell{in1}  id="divAddSell{{in1}}" name="divAddSell{{in1}}"  [style.display]="(prdSell.qty == 0) ? 'none' : 'block' ">
                 <div class="qty">
                     <span class="minus bg-success"  (click)="onMinus(in1, 'Sell')"><i class="fa fa-minus"></i></span>
                     <input type="text"  #nameSell{in1} id="nameSell{{in1}}" name="nameSell{{in1}}" class="form-control count" name="qty1"  ngModel [(ngModel)]="prdSell.qty" value="{{prdSell.qty}}" >
                     <span class="plus bg-success"  (click)="onPlus(in1, 'Sell')"><i class="fa fa-plus"></i></span>
                     <span #lblProductIdSell{in1}  id="lblProductIdSell{{in1}}" name="lblProductIdSell{{in1}}" style="display: none;" >{{prdSell.productId}}</span>
                 </div>
                </div> -->
              </div>  
            </div>
            </div>  
          </div>
          </div>   
         
        </div>
        </div>      
      </div>
    
    </div>
  </div>





</div>

<div class="video-bg">
  <div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h2 class="text-center text-white">What Our Client Says
        <span class="heading-style"><i></i><i></i><i></i></span>
      </h2>
    </div>
    <div class="col-md-6 ">
      <iframe id="frmVideo" class="w-100" allowfullscreen="" title="youtube Video Player" src="" style="height:380px;"></iframe>
    </div>
    <div class="col-md-6 align-self-center">
      <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
        <div id="divTestmonials" class="carousel-inner">
         
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<a href="https://api.whatsapp.com/send?phone=+917700033338" target="_blank" class="whatsapp whatsappfloating"><i class="fa fa-whatsapp"></i></a>


<div id="myModal" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">           
              <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
          <div class="modal-body">  
            <img id="imgPopup" class="img-fluid"  alt=""> 
          </div>
      </div>
  </div>
</div>
 