<div class="yellow-bg margin-from-nav">
    <div class="container-fluid">
    <div class="row">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="home">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Message</li>
        </ol>
    </div>
  </div>
  </div>
<div class="container-fluid contact-page">
    <div class="row">
        <div class="col-md-12">
            <div class="card" id="success" >
                <div class="card-header bg-primary text-white"><i class="fa fa-envelope"></i> Message
                </div>                
                <div class="card-body"></div>
                <div class="card-body"  >
                   Your order is successfully placed.<br />
                   Your order number is <b><span id="lblMessage" ></span></b>.<br />
                   Thank you for shopping with Amrutam.
                </div>
                <div class="card-body">&nbsp;</div>
            </div>  
            <div class="card" id="failed" >
                <div class="card-header bg-primary text-white"><i class="fa fa-envelope"></i> Message
                </div>                
                <div class="card-body"></div>
                <div class="card-body"  > 
                    Your order is incomplete. 
                    Our team will get in touch with you.
                    <span id="lblMessageFail" ></span>
                </div>
                <div class="card-body">&nbsp;</div>
            </div>  
        </div>  
    </div>
</div>