import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IndexService } from '../shared/index.service';
import { ProductsService } from '../shared/products.service';


declare var $: any;
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
 // styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  public id: string = "0";
  
  constructor(public serviceIndex : IndexService,public service : ProductsService, private router: Router) { }

  ngOnInit(): void {

    if(localStorage.getItem("userId") != null){
      this.id = localStorage.getItem("userId");
      this.service.GetCartCount( this.id).subscribe((res:any) =>{
        (<HTMLInputElement>document.getElementById('lblCart')).innerHTML = String(res);
        (<HTMLInputElement>document.getElementById('lblCartM')).innerHTML = String(res);
      })
    }
    this.ShowPopUp();
    this.serviceIndex.GetBanner();
    this.serviceIndex.GetTestimonials();
    this.serviceIndex.GetContent(1,true);
    this.refreshData();
  }

  ShowPopUp(){
    this.service.GetPopupBanner().subscribe((data: any)=>{
      if(data != ''){
        (<HTMLImageElement>document.getElementById('imgPopup')).src = this.serviceIndex.bannerPath + data; 
        $('#myModal').modal('show');
      }
    });   
  }

  refreshData(){
    this.service.GetProduct("0", this.id, "0", "1");
    this.service.GetProduct("0", this.id, "1", "0");
  }
  
  
  checkReload : number = 0;
  checkReloadSell : number = 0;
  tag : boolean = false;
  fnfillSize(size : string, sizeId : string, ind : number, catSizeId : number, colName : string ){ 
      
    if(colName == ''){ 
     if (ind == this.checkReload){ this.tag = true;}
    }else{
      if (ind == this.checkReloadSell){ this.tag = true;}
    }

    if(this.tag){
      this.tag = false;
      let sizeArray = size.split(',')
      let sizeIdArray = sizeId.split(',')
      let strHtml : string;
      let strSpanHtml : string;
      if(sizeArray.length > 1){
        for(let i =0; i  < sizeArray.length;i++){
          strHtml += '<option value='+ sizeIdArray[i] +'>'+ sizeArray[i] +'</option>';
        }  
          (<HTMLInputElement>document.getElementById('spanSize'+ colName + ind)).style.display = 'none';
      }else{ 
        //strSpanHtml = '<span>'+ sizeArray[0] +'</span>';
        strHtml = '<option value='+ sizeIdArray[0] +'>'+ sizeArray[0] +'</option>';
        (<HTMLInputElement>document.getElementById('spanSize'+ colName + ind)).innerHTML = sizeArray[0];
        (<HTMLInputElement>document.getElementById('drpSize'+ colName + ind)).style.display = 'none'; 
      }
      (<HTMLInputElement>document.getElementById('drpSize'+ colName + ind)).innerHTML = strHtml;
     if(catSizeId > 0){
      (<HTMLInputElement>document.getElementById('drpSize'+ colName + ind)).value = String(catSizeId);
     }
      this.fnGetPrice(ind, colName);
      if(colName == ''){ 
        this.checkReload=ind + 1; 
      }else{
        this.checkReloadSell=ind + 1; 
      }
    }
  }

  fnGetPrice(index : number, colName : string){
    this.service.GetPriceById((<HTMLInputElement>document.getElementById('drpSize'+ colName + index)).value).subscribe((data: any)=>{
     (<HTMLInputElement>document.getElementById('sPrice' + colName + index)).innerHTML = data; 
   });  
 }

 
 fnSizeChange(index : number, colName : string){
  this.fnGetPrice(index, colName);
 // (<HTMLInputElement>document.getElementById('name' + index)).value = "1" ;
  this.onMinus(index, colName);
}  


onMinus(index: number, colName : string){ 
  let prd =Number((<HTMLInputElement>document.getElementById('name'+ colName + index)).value);
  if(prd > 0){
    (<HTMLInputElement>document.getElementById('name'+ colName + index)).value = String(prd - 1);
    if(colName == ''){
      this.service.listProduct[index].qty =  prd - 1;
    }else{
      this.service.listTopSeller[index].qty=  prd - 1;
    }
    if(prd == 1){
      (<HTMLInputElement>document.getElementById('divAdd'+ colName + index)).style.display = 'none';
      (<HTMLInputElement>document.getElementById('btnAdd'+ colName + index)).style.display = 'block';
    }
    this.addCart(Number((<HTMLInputElement>document.getElementById('lblProductId'+ colName + index)).innerHTML)
    ,Number((<HTMLInputElement>document.getElementById('name'+ colName + index)).value), 
    (<HTMLInputElement>document.getElementById('drpSize'+ colName + index)).value);
  }
}
onPlus(index: number, colName : string){ 
  if(localStorage.getItem("userId") == null){
    (<HTMLHRElement>document.getElementById('loginID')).click();
  }
  else
  {
    let prd = Number((<HTMLInputElement>document.getElementById('name'+ colName + index)).value);
    if(prd == 0){ 
      (<HTMLInputElement>document.getElementById('divAdd'+ colName + index)).style.display = 'block';
      (<HTMLInputElement>document.getElementById('btnAdd'+ colName + index)).style.display = 'none';
    }
    (<HTMLInputElement>document.getElementById('name'+ colName + index)).value = String(prd +1);
    if(colName == ''){
      this.service.listProduct[index].qty =  prd + 1;
    }else{
      this.service.listTopSeller[index].qty =  prd + 1;
    }
    
    this.addCart( Number((<HTMLInputElement>document.getElementById('lblProductId'+ colName + index)).innerHTML)
    ,Number((<HTMLInputElement>document.getElementById('name'+ colName + index)).value), 
    (<HTMLInputElement>document.getElementById('drpSize'+ colName + index)).value
    );
  }
}
addCart( prodId : number, qty : number , size : string ){
   //console.log(size);
     this.service.AddToCart(Number(this.id)  , prodId ,qty, size).subscribe(
     res => { 
      //this.viewCart();
   });
 }
 
 onBuyNow(){
  this.router.navigate(['productlist']);
 }
 WeekMkt(){
  location.href = 'productlist';
 }

}
