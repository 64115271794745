<div class="yellow-bg margin-from-nav">
    <div class="container-fluid">
    <div class="row">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="home">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">My Order</li>
        </ol>
    </div>
  </div>
  </div>

  <div class="container-fluid cart-page">  
    <div class="border pad-10 mb-5">
        <div class="modal-body">
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab"> 

            <div class="row">
                <!--Middle Part Start-->
                <div id="content" class="col-sm-12">                    
                    <div class="table-responsive"  *ngFor="let ord of this.listOrderAddress;  let in=index ">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <td colspan="2" class="text-left">Order Details</td>
                                </tr>
                            </thead>
                            <tbody >
                                <tr>
                                    <td style="width: 50%;" class="text-left"> <b>Order ID:</b> {{ord.billNumber}}
                                       
                                       </td>
                                    <td style="width: 50%;" class="text-left">  <b>Date Added:</b> {{ord.orderDate | date: 'dd-MMM-yyyy' }} </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr> 
                                    <td style="  vertical-align: top;" class="text-left">Shipping Address</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">{{ord.fName}}
                                        <br>{{ord.address1}}
                                        <br>{{ord.address2}}
                                        <br>{{ord.state}},{{ord.city}}
                                        <br>{{ord.country}}
                                        <br >Pincode : - {{ord.pincode}}
                                    </td> 
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <td class="text-center">Product Name</td>
                                    <td class="text-center">Quantity</td>
                                    <td class="text-center">Price</td>
                                    <td class="text-center">Total</td> 
                                </tr>
                            </thead>
                            <tbody   *ngFor="let ord of this.listOrderProduct;  let in=index ">
                                <tr>
                                    <td class="text-left">{{ord.name}}</td>
                                    <td class="text-right">{{ord.qty}}</td>
                                    <td class="text-right">{{ord.price}}</td>
                                    <td class="text-right"><i class="fa fa-rupee"></i>&nbsp;{{ ord.qty * ord.price}}</td> 
                                </tr>
                            </tbody>
                            <tfoot> 
                                <tr>
                                    <td colspan="2"></td>
                                    <td class="text-right"><b>Total</b>
                                    </td>
                                    <td class="text-right"><i class="fa fa-rupee"></i>&nbsp;{{ this.strTotal }}</td>
                                </tr> 
                                <tr id="trDisc">
                                    <td colspan="2"></td>
                                    <td class="text-right"><b>Disc</b>
                                    </td>
                                    <td class="text-right"><i class="fa fa-rupee"></i>&nbsp;{{ this.strDisc }}</td>
                                </tr> 
                                <tr id="trFTotal">
                                    <td colspan="2"></td>
                                    <td class="text-right"><b>Final Total</b>
                                    </td>
                                    <td class="text-right"><i class="fa fa-rupee"></i>&nbsp;{{ this.strFTotal }}</td>
                                </tr> 
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

                </div>
            </div>         
        </div> 
    </div>
</div> 
