<div class="yellow-bg  margin-from-nav">
    <div class="container-fluid">
    <div class="row">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="home">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Cart</li>
        </ol>
    </div>
  </div>
  </div>
  


<div class="container-fluid cart-page">  
    <div class="pad-10 text-center card-header bg-primary text-white">
           <h5>Delivery Date :-&nbsp;<label id="lblDeliveryDate"></label></h5>
    </div>
    <div class="border pad-10 mb-5">
        <div class="row mr-0 ml-0 border-bottom d-none d-md-flex">
            <div class="col-md-4">
                <h5>Product</h5>
            </div>
            <div class="col-md-2 pl-0 pr-0">
                <h5>Price</h5>
            </div>
            <div class="col-md-2 pl-0 pr-0">
                <h5>Quantity</h5>
            </div>
            <div class="col-md-2 pl-0 pr-0">
                <h5>Subtotal</h5>
            </div>
            <div class="col-md-2 pl-0 pr-0">
                <h5>Action</h5></div>
        </div>

        <div class="row mr-0 ml-0" *ngFor="let prd of this.listCart;  let in=index">
            <div class="col-md-2 col-sm-2 col-xs-6 col-4 pl-0 pr-0 align-self-md-center">
                <img src="{{service.imgPath}}{{prd.image}}" class="img-fluid">
            </div>
            <div class="col-md-2 col-sm-2 col-xs-6 col-8 pl-0 pr-0 align-self-md-center">
                <h6 class="text-left">{{prd.pTitle}}</h6>
            </div>
            <div class="col-md-8 col-sm-8 align-self-md-center mobile-row cart_mobile_view_wrapper">
                <div class="row ml-0 mr-0">                   
                    <div class="col-md-3 col-sm-3 pl-0 pr-0 d-none d-sm-block">
                        <p><i class="fa fa-rupee"></i> {{prd.Price}}</p>
                    </div>
                    <div class="col-3 offset-2 col-md-3 col-sm-3 offset-sm-0 offset-md-0 pl-0 pr-0 cart_mobile_view">
                        <p class="text-sm-left">{{prd.Size}} * {{prd.qty}}&nbsp;Qty</p>
                    </div>
                    <div class="col-4 col-md-3 col-sm-3 item_price">
                        <p class="text-sm-left"><i class="fa fa-rupee"></i> {{prd.Price * prd.qty }}</p>
                    </div>
                    <div class="col-3 col-md-3  col-sm-3 mobile_del_icon">
                        <p class="text-right text-sm-left"><i class="fa fa-trash trash"  (click)="fnDeleteCart(prd.productId, in)" ></i></p>
                    </div>        
                </div>
            </div>
            <div class="col-md-12">
                <hr>
            </div>
        </div> 
        <div class="row" id="divTotal">
            <div class="col-md-4 offset-md-8 col-sm-10 offset-sm-2 offset-6 col-6 ">
                <h5> Total: <i class="fa fa-rupee"></i>&nbsp;<span id="lblTotal" #lblTotal name="lblTotal"></span></h5>
            </div>
        </div>
        <div class="row" id="divFinalTotal" >
            <div class="col-md-4 offset-md-4 col-6"> 
                <div class=" coupon-form">
                    <div class="form-group"> 
                        <div class="input-group"><input type="text" id="txtCode" #txtCode name="txtCode" class="form-control coupon" name="" placeholder="Coupon code" > <span class="input-group-append space"> <button id="btnDisc" class="btn btn-success coupon-btn mb-0"  (click)="fnCalculateDisc()"><i class="fa fa-tag"></i></button> </span> </div>
                    </div> 
              </div>
            </div>
              <div class="col-md-4  col-6 ">
                   <h5 class="text-danger"> - Disc.  <i class="fa fa-rupee"></i>&nbsp;<span id="lblDisc" #lblDisc name="lblDisc">0</span></h5>
              </div>
               
              <div class="col-md-4 offset-md-8 col-sm-10 offset-sm-2 offset-6 col-6 ">
                   <h5>Final Total: <i class="fa fa-rupee"></i>&nbsp;<span id="lblFinalTotal" #lblFinalTotal name="lblFinalTotal">0</span></h5>
              </div>
          </div>
        <div class="row"  id="divEmpty">
            <div class="col-md-12">
                <p class="text-center">Your cart is empty. </p>
            </div>      
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-md-6 col-sm-6 col-6">
            <button id="btnContinue" class="btn btn-success" (click)="fnContinue()">Continue Shopping </button>
        </div>
        <div class="col-md-6 col-sm-6 col-6">
           <!-- <a  id="btnCheckOut"  class="btn btn-success pull-right" href="checkout" >Checkout</a> 
           <button id="btnCheckOut"  class="btn btn-success pull-right" (click)="fnCheckOut()">Checkout </button> -->
            <button id="btnCheckOut" class="btn btn-success pull-right" (click)="placeOrder()"  >Place Order</button>
        </div>
    </div>
</div>
