import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http'; 
import { Menu } from './menu.model';
import {Index } from './index.model'; 

@Injectable({
  providedIn: 'root'
})
export class IndexService {
  public listMenu : Menu[];
  listBanner : Index[];
  listTestimonials : Index[];
  listContent : Index[];
// readonly rootURL ="http://localhost:2853/api"; 
   readonly rootURL ="https://service.aranyamamrutam.com/api"; 
//  public readonly bannerPath = "http://localhost:1045/AmrutamAdmin/Upload/Banner/";
   public readonly bannerPath = "https://admin.aranyamamrutam.com/Upload/Banner/";

  constructor(private http : HttpClient) { }

 GetMenu( catId  : number){ 
      let strMenu : string;
      let strSPMenu : string;
      this.http.get(this.rootURL+'/Menu').toPromise().then(res => {
      this.listMenu = res as Menu[]
     // console.log(this.listMenu);
      for(let i =0; i < this.listMenu.length; i++){
        if(i == 0){
          strMenu = '<a class="dropdown-item" href="products/' + this.listMenu[i]["categoryId"] +  '">' + this.listMenu[i]["category"] +  '</a>';
        }else{
          if(String(this.listMenu[i]["categoryId"])  == '11'){
            strSPMenu = '<a class="nav-link" href="products/' + this.listMenu[i]["categoryId"] +  '">' + this.listMenu[i]["category"] +  '</a>'
          }else{
            strMenu += '<a class="dropdown-item" href="products/' + this.listMenu[i]["categoryId"] +  '">' + this.listMenu[i]["category"] +  '</a>';
          }
        }         
      }
      (<HTMLInputElement>document.getElementById('main_nav')).insertAdjacentHTML('beforeend', strMenu);
      (<HTMLInputElement>document.getElementById('main_nav_sp_Item')).insertAdjacentHTML('beforeend', strSPMenu);
    });
  }
  


   GetUserName(id : number){
   //  console.log(this.rootURL+'/User/' + id);
    return this.http.get(this.rootURL+'/User/' + id);
   }

   GetBanner(){   
     let strBanner :string;
     let strBannerCnt :string;
     this.http.get(this.rootURL+'/Menu/?sMenu=b&id=0').toPromise().then(res => { 
        this.listBanner = res as Index[];
        for(let i =0; i < this.listBanner.length; i++){
          if(i == 0){
            strBanner = '<div class="carousel-item active">';
            strBanner += '<img class="d-block w-100" src="'+ this.bannerPath + '' + this.listBanner[i]["content"]  +'" >';
            strBanner += '</div>';
            strBannerCnt = '<li data-target="#carouselExampleIndicators" data-slide-to="' + i +'" class="active"></li>';
          }else{
            strBanner += '<div class="carousel-item ">';
            strBanner += '<img class="d-block w-100" src="'+ this.bannerPath + '' + this.listBanner[i]["content"]  +'" >';
            strBanner += '</div>';
            strBannerCnt += '<li data-target="#carouselExampleIndicators" data-slide-to="' + i +'" ></li>';
          }   
        }
      (<HTMLDivElement>document.getElementById('divBanner')).innerHTML = strBanner;
      (<HTMLOListElement>document.getElementById('divCnt')).innerHTML = strBannerCnt;
    });
  }

  GetTestimonials(){   
    let strTestimonials :string; 
    this.http.get(this.rootURL+'/Menu/?sMenu=t&id=0').toPromise().then(res => { 
      let re = /\<p>/gi;
       this.listTestimonials = res as Index[];
       for(let i =0; i < this.listTestimonials.length; i++){
         if(i == 0){
          (<HTMLIFrameElement>document.getElementById('frmVideo')).src = this.listTestimonials[i]["content"].replace(/<[^>]*>/g, '');
         }else if(i == 1){
          strTestimonials = '<div class="carousel-item active">';
          strTestimonials +=  this.listTestimonials[i]["content"].replace(re,'<p class="text-white text-center">');
          strTestimonials += '</div>'; 
         }else{
          strTestimonials += '<div class="carousel-item ">';
          strTestimonials += this.listTestimonials[i]["content"].replace(re,'<p class="text-white text-center">');
          strTestimonials += '</div>'; 
         }   
       }
     (<HTMLDivElement>document.getElementById('divTestmonials')).innerHTML = strTestimonials; 
   });
 }

 GetContent(id : number, tag : boolean){    
  this.http.get(this.rootURL+'/Menu/?sMenu=cd&id=' + id).toPromise().then(res => { 
    let re = /\<p>/gi;
     this.listContent = res as Index[];
     for(let i =0; i < this.listContent.length; i++){
       if(tag){
        (<HTMLDivElement>document.getElementById('divContent')).innerHTML = this.listContent[i]["content"].substring(0,368) + '..&nbsp;<a href="aboutUs" >Read More</a>'; 
       }else{
        (<HTMLDivElement>document.getElementById('divContent')).innerHTML = this.listContent[i]["content"];
       }
     }
 });
}

}
