import { Injectable, Testability } from '@angular/core';
import { Products } from './products.model'; 
import { Order } from './order.model'; 
//import { OrderAddress } from './order-address.model'; 
//import {OrderProduct } from './order-product.model';
import{ Menu } from './menu.model';
import { HttpClient,HttpClientModule,  HttpHeaders, HttpParams } from "@angular/common/http";
import { Users } from './users.model';
import { from } from 'rxjs'; 


@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  listProduct : Products;
  listTopSeller : Products;
  listOrder : Order;

 // readonly rootURL ="http://localhost:2853/api"; 
  readonly rootURL ="https://service.aranyamamrutam.com/api"; 
 // public readonly imgPath = "http://localhost:1045/AmrutamAdmin/Upload/";
  public readonly imgPath = "https://admin.aranyamamrutam.com/Upload/";
 

  constructor(private http : HttpClient) { }

  GetProduct(cat:string, id : string, topS :string,newArr : string){   
    this.http.get(this.rootURL+'/Product/', {
      params: {
        category: String(cat),
        userId : id,
        topS : topS,
        newArr : newArr
      },
      observe: 'response'
    })
    .toPromise()
    .then(response => {
        if(Number(topS) == 0 ){
          this.listProduct = response.body as Products
          return this.listProduct;
        }else{
          this.listTopSeller = response.body as Products
          
          return this.listTopSeller;
        }
      })
    .catch(console.log);
  }
  
  GetCartCount(id : string){
    return this.http.get(this.rootURL + '/product/GetCartCount/?userId=' + id)
  }
  
  GetPriceById(sizeId : string){
    return this.http.get(this.rootURL+'/Price/' + sizeId);
   }
  
   AddToCart(id : number, productId : number, qty : number, size : string){
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8'}) };
    return this.http.post(this.rootURL+'/product/?id='+ id +'&productId='+ productId +'&qty='+ qty +'&size='+ size , options);    
  }
  AddToCartList(id : number, productId : number ,sUnt : number, price : number,dUnit : string,catId : number){
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8'}) };
    return this.http.post(this.rootURL+'/ProductList/?id='+ id +'&productId='+ productId +'&sUnt='+ sUnt +'&price='+ price + '&dUnit='+ dUnit +'&catId='+ catId , options);    
  }
  AddCartAddress(id : number, aId : number ){
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8'}) };
    return this.http.post(this.rootURL+'/ProductList/?id='+ id +'&aId='+ aId, options);    
  }

  DeleteCart(productId : number, userId : number){
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8'}) };
    return this.http.post(this.rootURL+'/cart/?productId=' + productId + '&userId=' + userId, options);
  }

  GetCart(id : string){   
    return this.http.get(this.rootURL+'/Cart/?userId=' + id);
  }
  GetCountryCity(id : number){
    return this.http.get(this.rootURL+'/Order/' + id);
  }
  GetAddress( userId : string){
    return this.http.get(this.rootURL+'/User/?userId=' + userId);
  }
  AddAddress(frm: object){
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8'}) };
    return this.http.post(this.rootURL+'/User/', frm , options);    
  }
  DeleteAddress(aId : number){
    return this.http.delete(this.rootURL+'/user/?aId=' + aId);
  }
  //AddOrder(aid : number, userId : number,paymentId : string){
  //  let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8'}) };
  //  return this.http.post(this.rootURL+'/order/?aid='+ aid +'&userId='+ userId + '&pId='+ paymentId, options);    
  //}
  AddOrder(aid : number, userId : number,paymentId : string){
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8'}) };
    return this.http.post(this.rootURL+'/order/?aid='+ aid +'&userId='+ userId + '&pId='+ paymentId, options);    
  }

  AddOrderWithOffer(aid : number, userId : number,paymentId : string, disc : string, offerCode:string){
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8'}) };
    return this.http.post(this.rootURL+'/order/?aid='+ aid +'&userId='+ userId + '&pId='+ paymentId
    +'&disc='+ disc + '&offerCode='+ offerCode, options);    
  }

  PaymentStatus( userId : number, status : string ,paymentId : string){
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8'}) };
    return this.http.post(this.rootURL+'/order/?userId='+ userId + '&status=' + status + '&pId='+ paymentId, options);    
  }
  OrderStatus( paymentId : string, status : string ,billId : string){
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8'}) };
    return this.http.post(this.rootURL+'/order/?paymentId='+ paymentId + '&status=' + status + '&billId='+ billId, options);    
  }
  CheckUser(userId : string,pass : string){
    return this.http.get(this.rootURL+'/user/?loginId=' + userId + '&pass=' + pass);    
  }
  CheckDuplicateUser(userMobileNo : string){
   //console.log(userEmailId);
    return this.http.get(this.rootURL+'/user/?userId=0&userName='+ userMobileNo +'&userMessage=register');    
  }
  AddCustomer(userName : string,emailId : string,mobileNo : string,pass : string){
    if(emailId == ''){
      emailId = 'a@c.com';
    }
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8'}) };
    return this.http.post(this.rootURL+'/user/?userName='+ userName +'&emailId='+ emailId +'&mobileNo='+ mobileNo +'&pass='+ pass , options);    
  }
  GetCustomerOrder(usrId: string){   
    this.http.get(this.rootURL+'/order/', {
      params: {
        userId : usrId 
      },
      observe: 'response'
    }).toPromise()
    .then(response => {
      this.listOrder = response.body as Order
      //console.log(this.listOrder);
        return this.listOrder;
      })
    .catch(console.log);
    }

    GetCustomerAddress(billId : string, usrId: string){   
    return  this.http.get(this.rootURL+'/order/', {
        params: {
          bId : billId,
          userId : usrId
        } 
      });
    }

      GetCustomerProduct( billId : string, usrId: string){   
       return this.http.get(this.rootURL+'/order/', {
          params: {
            bId : billId,
            userId : usrId,
            tag : '0'
          } 
        });
      }

      GetToken(purpose : string,amount : number,buyerName : string,email : string,phone : string){ 
        return this.http.get(this.rootURL+'/token/?purpose='+ purpose +'&amount='+ amount +'&buyerName='+ buyerName +'&email='+ email +'&phone='+ phone);    
      }

      GetBillAmount(billId : string, usrId: number){ 
        return this.http.get(this.rootURL+'/user/?bId='+ billId +'&userId='+ usrId);    
      }
      
   GetDeliveryDate(id : number){
     return this.http.get(this.rootURL+'/Menu/?userId=' + id);
    }
    GetPopupBanner(){
      return this.http.get(this.rootURL+'/Price/');
     }
    
     GetCouponDisc( userId : string, offerCode : string){
      return this.http.get(this.rootURL+'/Offers/GetOffers?userId=' + userId + '&offerCode=' + offerCode);
     }

     CheckOffers(userId : string){
      return this.http.get(this.rootURL+'/Offers/CheckOffers?offer=1');
     }

     GetDiscount(userId : string){
      return this.http.get(this.rootURL+'/Offers/GetOffers?userId=' + userId);
     }


     UpdateCartForOffer(disc : string,offerCode: string, userId : string){
      let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8'}) };
      return this.http.post(this.rootURL+'/offers/SetOffers?disc=' + disc + '&offerCode=' + offerCode + '&userId=' + userId, options);
     }

     GetCateogyForProduct(id : string){
      return this.http.get(this.rootURL+'/ProductList/');
     }

     GetProductList(id : string){
      return this.http.get(this.rootURL+'/ProductList/' + id);
     }

  }
