import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IndexService } from './shared/index.service';
import { convertActionBinding } from '@angular/compiler/src/compiler_util/expression_converter';
 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
 //styleUrls: ['../vendor/bootstrap/css/bootstrap.min.css','../css/style.css','../css/fontawesome/css/font-awesome.min.css']
})
export class AppComponent {
  title = 'Amrutam';
  checkOutJs : boolean = false;
   
  constructor (private router: Router, public serviceIndex : IndexService) { 
    //if(this.router.url == '/login'){
      //this.checkOutJs = true;
   // }
    this.serviceIndex.GetMenu(0);  
    //console.log(localStorage.getItem("userId"));  
   if(localStorage.getItem("userId") != null){
    this.serviceIndex.GetUserName(Number(localStorage.getItem("userId"))).subscribe((data: any)=>{
      if(data == ''){
        localStorage.clear();
      }else{
        (<HTMLHRElement>document.getElementById('userName')).innerHTML = data; //userName
       (<HTMLHRElement>document.getElementById('userNameM')).innerHTML = data;
       // (<HTMLInputElement>document.getElementById('userDiv')).style.display = "block";
       (<HTMLInputElement>document.getElementById('myAccount')).style.display = "block";
        (<HTMLInputElement>document.getElementById('loginDiv')).style.display = "none";   
        (<HTMLHRElement>document.getElementById('loginIDM')).style.display = "none";        
      }
    });  
   }
  }
}
