import { Component, OnInit } from '@angular/core';
import { IndexService } from '../shared/index.service';
import { ProductsService } from '../shared/products.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(public serviceIndex : IndexService,public service : ProductsService) { }

  ngOnInit(): void {
    this.serviceIndex.GetContent(1, false);
    if(localStorage.getItem("userId") != null){ 
      this.service.GetCartCount(localStorage.getItem("userId")).subscribe((res:any) =>{
        (<HTMLInputElement>document.getElementById('lblCart')).innerHTML = String(res);
        (<HTMLInputElement>document.getElementById('lblCartM')).innerHTML = String(res);
      })
    }
  }

}
