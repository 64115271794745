import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductsComponent } from './products/products.component';
import { ProductsService } from './shared/products.service';
import { IndexComponent } from './index/index.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { MessageComponent } from './message/message.component';
import { LoginComponent } from './login/login.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { PolicyComponent } from './policy/policy.component';
import { TermsComponent } from './terms/terms.component'; 
import { MyorderComponent } from './myorder/myorder.component';
import { OrderinfoComponent } from './orderinfo/orderinfo.component';
import { PlaceorderComponent } from './placeorder/placeorder.component';
import { RazorTestComponent } from './razor-test/razor-test.component'; 
import { RazorService } from './shared/razor.service';
import { ProductlistComponent } from './productlist/productlist.component';

@NgModule({
  declarations: [
    AppComponent,
    ProductsComponent,
    routingComponents,
    IndexComponent,
    CartComponent,
    CheckoutComponent,
    MessageComponent,
    LoginComponent,
    AboutUsComponent,
    DisclaimerComponent,
    PolicyComponent,
    TermsComponent, 
    MyorderComponent,
    OrderinfoComponent,
    PlaceorderComponent,
    RazorTestComponent,
    ProductlistComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule, 
    ReactiveFormsModule
  ], 
  providers: [ProductsService,RazorService],
  bootstrap: [AppComponent]
})
export class AppModule { }
