<div class="yellow-bg margin-from-nav">
    <div class="container-fluid">
    <div class="row">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="home">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">CheckOut</li>
        </ol>
    </div>
  </div>
  </div>
<div class="container-fluid contact-page">
    <div class="row">
        <div class="col-md-8">
            <div class="card" >
                <div class="card-header bg-primary text-white"><i class="fa fa-envelope"></i> Shipping Details
                </div>
                <div class="card-body"  id="divAddress" >
                    <div class="form-check"  *ngFor="let lstAdd of listAddress;  let in=index">
                        <input class="form-check-input"   type="radio" name="exampleRadios" id="exampleRadios{{in}}" value="{{ lstAdd.aId }}"  [checked]="in === 0" (change)="onSelectionChange(lstAdd.aId)" >
                        <label class="form-check-label" for="exampleRadios{{in}}">
                            {{ lstAdd.fname + ' ' + lstAdd.lname  }} <br />
                            {{ lstAdd.address + ', ' + lstAdd.address2 + ', ' }} <br />
                            {{ lstAdd.Country + ', ' + lstAdd.State  + ', ' + lstAdd.city + ' - ' + lstAdd.pincode }}
                        </label>
                      </div>     
                      <div class="row mt-3">
                        <div class="col-md-12">         
                            <div class="mx-auto">
                                <button type="submit" class="btn btn-success text-right" (click)="placeOrder()"  >Place Order</button>&nbsp;
                                <button type="submit" id="btnAddAddress" class="btn btn-success text-right" (click)="showAddress()">Add Address</button>&nbsp;
                                <button type="submit" class="btn btn-secondary text-right" (click)="DeleteAddress()">Delete Address</button>
                            </div>
                        </div>
                    </div>              
                                   
                </div>


                <form  [formGroup]="angForm" >
                <!-- Add Address -->
                <div class="card-body" id="divAddForm" style="display: none;">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="name">First Name</label>
                                <input type="text" class="form-control" id="fname"  formControlName="fname"  placeholder="First Name" required>
                            </div>
                            <div *ngIf="angForm.controls['fname'].invalid && (angForm.controls['fname'].dirty || angForm.controls['fname'].touched)" class="alert alert-danger">
                                <div *ngIf="angForm.controls['fname'].errors.required">
                                  First Name should not be blank !
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="name">Last Name</label>
                                <input type="text" class="form-control" id="lname"  formControlName="lname"  placeholder="Last Name" required>
                            </div>
                            <div *ngIf="angForm.controls['lname'].invalid && (angForm.controls['lname'].dirty || angForm.controls['lname'].touched)" class="alert alert-danger">
                                <div *ngIf="angForm.controls['lname'].errors.required">
                                  Last Name should not be blank !
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="address">Address</label>
                                <input type="text" class="form-control" id="address" formControlName="address" placeholder="Address Line 1" required>
                            </div>                            
                            <div *ngIf="angForm.controls['address'].invalid && (angForm.controls['address'].dirty || angForm.controls['address'].touched)" class="alert alert-danger">
                                <div *ngIf="angForm.controls['address'].errors.required">
                                  Address should not be blank !
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="email">Address Line 2</label>
                                <input type="text" class="form-control" id="address2" formControlName="address2" placeholder="Address Line 2" required>
                            </div>                            
                            <div *ngIf="angForm.controls['address2'].invalid && (angForm.controls['address2'].dirty || angForm.controls['address2'].touched)" class="alert alert-danger">
                                <div *ngIf="angForm.controls['address2'].errors.required">
                                  Address should not be blank !
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="message">Country</label>
                                <select class="form-control" id="Country" formControlName="Country">
                                  </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="message">State</label>
                                <select class="form-control" id="State" formControlName="State"> 
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="message">City</label>
                                <select class="form-control" id="city" formControlName="city"> 
                                  </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="message">Pincode</label>
                                <input type="number" class="form-control" id="pincode" formControlName="pincode" placeholder="Pincode" required>
                            <div *ngIf="angForm.controls['pincode'].invalid && (angForm.controls['pincode'].dirty || angForm.controls['pincode'].touched)" class="alert alert-danger">
                                <div *ngIf="angForm.controls['pincode'].errors.required">
                                  Pincode is not valid !
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="mobile">Mobile No</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">+ 91</span>
                                    </div>
                                    <input type="number" class="form-control" formControlName="mobileNo" minlength="8" max="10" placeholder="Mobile No" aria-label="Username" aria-describedby="basic-addon1">
                                  </div>                                  
                            <div *ngIf="angForm.controls['mobileNo'].invalid && (angForm.controls['mobileNo'].dirty || angForm.controls['mobileNo'].touched)" class="alert alert-danger">
                                <div *ngIf="angForm.controls['mobileNo'].errors.required">
                                  Mobile no. is not valid !
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input type="email" class="form-control" id="email" formControlName="email" placeholder="Email" required>
                            </div>                                
                            <div *ngIf="angForm.controls['email'].invalid && (angForm.controls['email'].dirty || angForm.controls['email'].touched)" class="alert alert-danger">
                                <div *ngIf="angForm.controls['email'].errors.required">
                                  Email is not valid !
                                </div>
                            </div>
                        </div>                       
                    </div>
                    <div class="row">
                        <div class="col-md-12">         
                            <div class="mx-auto">
                                <button type="submit"  [disabled]="angForm.pristine || angForm.invalid"  class="btn btn-success text-right" (click)="onSubmit()">Place Order</button>
                            </div>
                        </div>
                    </div> 
                     
                </div>
            </form>
                <!-- End -->


            </div>
        </div> 
        <div class="col-md-4 showCart">
            <div class="card bg-light mb-3">
                <div class="card-header bg-success text-white"><i class="fa fa-home"></i> Cart</div>
                <div class="card-body">
                    <div class="cart-body cartText">
                        <div  class="col-auto align-self-md-center mobile-row"  *ngFor="let prd of this.listCart;  let in=index">
                            <div  class="row"><div class="pl-0 pr-0">{{ in + 1 }}.&nbsp;</div>
                            <div  class="col-md-4 col-sm-4 titleWidth pl-0 pr-0">  {{prd.pTitle}} </div>
                            <div  class="col-md-4 col-sm-4 pl-0 pr-0"> {{prd.Size}} * {{prd.qty}}&nbsp;Qty </div>
                            <div  class="col-md-2 col-sm-2 pl-0 pr-0 text-right">
                                <i class="fa fa-rupee"></i> {{prd.Price * prd.qty }} </div></div>
                             </div>
                        </div>
                             <div class="cart-body"><hr >
                                <div class="col-md-12 text-right">
                                 <h5>Total&nbsp;<i  class="fa fa-rupee"></i>
                                     <span  id="lblTotal" name="lblTotal">106</span></h5>
                                </div>
                                <div class="col-md-12 text-right" id="divDisc" >
                                    <h5>Disc&nbsp;<i  class="fa fa-rupee"></i>
                                        <span  id="lblDisc" #lblDisc name="lblDisc">0</span></h5>
                                </div>
                                <div class="col-md-12 text-right" id="divFTotal" >
                                    <h5>Final Total&nbsp;<i  class="fa fa-rupee"></i>
                                        <span  id="lblFTotal" #lblFTotal name="lblFTotal">0</span></h5>
                                </div>

                            </div> 
             </div> 
            </div>
        </div>     
    </div>
  
    </div>
  
   